import { useMemo } from 'react';
import { Paper, Typography, LinearProgress } from '@material-ui/core';
import Chart from '@components/core/Chart';

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default function Tasks(props) {
  const { data } = props
  if (!data) {
    return (
      <>
        <Typography variant="h6" color="text">
          每日帳號狀態
        </Typography>
        <Paper elevation={0}>
          <LinearProgress />
        </Paper>
      </>
    )
  }

  const groupedData = groupBy(
    data?.accounts_status_by_date || [],
    'date'
  )
  const sourceData = (data?.dates || []).map(date => {
    if (!groupedData[date]) {
      return { date, alive: 0, block: 0 }
    }

    const alive = groupedData[date].find(row => row.status === 'alive')?.count || 0
    const block =  groupedData[date]
      .filter(row => row.status !== 'alive')
      .reduce((pre, cur) => cur.count + pre, 0)

    return { date, alive, block }
  })

  const OPTION = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
    },
    legend: {
      data: ['alive', 'block']
    },
    dataset: {
      dimensions: ['date', 'alive', 'block'],
      source: sourceData,
    },
    xAxis: { type: 'category' },
    yAxis: {},
    series: [
      {
        type: 'line',
      },
      {
        type: 'line',
      },
    ]
  }

  return (
    <>
      <Typography variant="h6" color="text">
        每日帳號狀態
      </Typography>
      <Paper elevation={0}>
        <Chart
          option={OPTION}
          style={{
            height: '300px',
          }}
        />
      </Paper>
    </>
  );
}

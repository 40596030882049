import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { useLayoutState } from '@/context/LayoutContext';
// pages
import Dashboard from '@/pages/dashboard';
import Accounts from '@/pages/accounts';
import AccountDetail from '@/pages/accounts/detail';
import Flows from '@/pages/flows';
import FlowsCalendar from '@/pages/flowsCalendar';
import FlowsForm from '@/pages/flowsForm';
import BatchAccounts from '@/pages/batchAccounts';
import BatchAccountsCreate from '@/pages/batchAccounts/create';
import Tags from '@/pages/tags';
import Materials from '@/pages/materials';
import Targets from '@/pages/targets';
import MaterialsCreate from '@/pages/materials/create';

// components
import Header from './Header';
import Sidebar from './Sidebar';
// styles
import useStyles from './styles';

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route exact path="/app/dashboard" component={Dashboard} />
            <Route exact path="/app/accounts" component={Accounts} />
            <Route path="/app/accounts/:id" component={AccountDetail} />
            <Route exact path="/app/flows" component={Flows} />
            <Route exact path="/app/calendar" component={FlowsCalendar} />
            <Route exact path="/app/flow-form" component={FlowsForm} />
            <Route exact path="/app/batch-accounts" component={BatchAccounts} />
            <Route
              exact
              path="/app/batch-accounts/create"
              component={BatchAccountsCreate}
            />
            <Route exact path="/app/tags" component={Tags} />
            <Route exact path="/app/materials" component={Materials} />
            <Route
              exact
              path="/app/materials/create"
              component={MaterialsCreate}
            />
            <Route exact path="/app/targets" component={Targets} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);

import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import useStepStore from '@hooks/useStepStore';
import DataTable from '@components/core/DataTable';
import Select from '@components/core/Select';
import TagsSetting from '@components/shared/TagsSetting';

const platforms = [
  {
    name: 'Facebook',
    value: 'facebook',
  },
];

const Step2 = ({ handleNext }) => {
  const [done, setDone] = useState(false);
  const { formData, excelData, setFormData } = useStepStore();

  const handleFormData = (data) => {
    setFormData({
      ...formData,
      ...data,
    });
  };

  useEffect(() => {
    if (formData) {
      Object.keys(formData).length >= 3 && setDone(true);
    }
  }, [formData]);

  return (
    <Box sx={{ pt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Select
            autoWidth
            variant="outlined"
            label="檢舉網址欄位"
            name="report_entity_field"
            options={excelData?.fields?.map((field) => ({
              name: field || '',
              value: field || '',
            }))}
            onChange={({ target }) =>
              handleFormData({
                report_entity_field: target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Select
            autoWidth
            variant="outlined"
            label="平台"
            name="report_entity_field"
            options={platforms.map((field) => ({
              name: field.name,
              value: field.value,
            }))}
            onChange={({ target }) =>
              handleFormData({
                platform: target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TagsSetting
            onChange={(value) => {
              handleFormData({
                tags: value,
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <DataTable
            title="資料預覽"
            data={excelData?.rows?.map((row) => row.slice(0, 20))}
            columns={excelData?.fields?.map(f => f || '')}
            options={{
              filter: false,
              search: false,
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{ mr: 1 }}
          disabled={!done}
        >
          下一步
        </Button>
      </Box>
    </Box>
  );
};

export default Step2;

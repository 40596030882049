import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

import YTSetting from './YTSetting';
import useStyles from './styles';

const initData = {
  url: '',
  channel_id: '',
  target_id: '',
}

const Step2 = ({ flow, handleNext, handleBack, onNext }) => {
  const [done, setDone] = useState(false);
  const [data, setData] = useState(initData);
  const [isAbleToAdd, setIsAbleToAdd] = useState(false);
  const [settings, setSettings] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (
      settings.length > 0 &&
      settings.every(setting => setting.accounts && setting.accounts.length > 0)
    ) {
      setDone(true)
    }

    if (settings.length === 0) {
      setDone(false)
    }
  }, [settings, setDone])

  useEffect(() => {
    if (['yt.subscribe.channel'].includes(flow.action)) {
      if (data.channel_id !== '') {
        return setIsAbleToAdd(true)
      }
    }
    if (['yt.like.video', 'yt.comment.video', 'yt.reply.comment'].includes(flow.action)) {
      if (data.url !== '') {
        return setIsAbleToAdd(true)
      }
    }

    return setIsAbleToAdd(false)
  }, [data, flow.action])

  useEffect(() => {
    if (flow.action === 'yt.create.video') {
      setSettings([{ post_url: '', channel_id: '' }])
    }
  }, [flow.action])

  const handleOnNext = () => {
    onNext(settings)
    handleNext()
  }

  const handleSetUrl = (e) => {
    setData({ ...data, url: e.target.value })
  }

  const handleSetChannelID = (e) => {
    setData({ ...data, channel_id: e.target.value })
  }

  const handleAddData = () => {
    setData(initData)
    setSettings(
      [
        ...settings,
        { post_url: data.url, channel_id: data.channel_id },
      ]
    )
  }

  const handleSettingChange = (idx) => (setting) => {
    settings[idx] = setting
    setSettings([...settings])
  }

  const handleSettingDelete = (idx) => () => {
    const newSettings = settings.map((s, i) => i === idx ? null : s).filter(s => !!s)
    setSettings(newSettings)
  }

  const handdleStatusChange = (setting) => (status) => {
    if (status === '') {
      return
    }
    setDone(false)
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Grid container spacing={3}>
        {
          flow.action === 'yt.subscribe.channel' &&
          <Grid item xs={4}>
            <TextField
              className={classes.formItem}
              variant="outlined"
              label="頻道ID"
              onChange={handleSetChannelID}
              value={data.channel_id}
            />
          </Grid>
        }
        {
          ['yt.like.video', 'yt.comment.video', 'yt.reply.comment'].includes(flow.action) &&
          <Grid item xs={9}>
            <TextField
              className={classes.formItem}
              variant="outlined"
              label="影片(留言)網址"
              onChange={handleSetUrl}
              value={data.url}
            />
          </Grid>
        }
        {
          flow.action !== 'yt.create.video' &&
          <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAddData}
              disabled={!isAbleToAdd}
            >
              新增
            </Button>
          </Grid>
        }
        <Grid item xs={12}>
          {
            settings.map((setting, idx) => 
              <YTSetting
                key={idx}
                idx={idx+1}
                flow={flow}
                setting={setting}
                onSettingChange={handleSettingChange(idx)}
                onSettingDeleted={handleSettingDelete(idx)}
                onStatusChange={handdleStatusChange(setting)}
              />
            )
          }
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginTop: 32 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          style={{ marginRight: 12 }}
        >
          上一步
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnNext}
          disabled={!done}
        >
          下一步
        </Button>
      </Box>
    </Box>
  );
};

export default Step2;

import useSWR from 'swr';
import fetcher from '@/libs/fetcher';
import { useUserState } from '@/context/UserContext';

export default function useNotifications(type) {
  const { user } = useUserState();
  const { data, mutate } = useSWR(`/users/${user.id}/notifications`, (url) =>
    fetcher({ url }),
  );

  function markAsRead(id) {
    return fetcher({
      url: `/users/${user.id}/notifications/${id}`,
      method: 'PATCH',
      data: {
        is_read: true,
      },
    })
    .then(_ => mutate())
  }

  return {
    notifications: data?.data || [],
    markAsRead,
  }
}

import React, { useState } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStepStore from '@hooks/useStepStore';
import AccountList from '@components/shared/AccountList';
import Info from '@components/core/Info';
import fetcher from '@/libs/fetcher';
import { useUserState } from '@/context/UserContext';

const Step3 = () => {
  const history = useHistory();
  const { formData, excelData, clearState } = useStepStore();
  const { user } = useUserState();
  const [data, setData] = useState({});
  const [notify, setNotify] = useState({ open: false, message: '' });

  const onChange = (fieldName, value) => {
    setData({
      ...data,
      [fieldName]: value,
    });
  };

  const handleAccountsSelected = (indices, rows) => {
    const ids = rows.map((row) => row.id);
    onChange('accounts', ids);
  };

  const onSubmit = async () => {
    const index = excelData.fields.findIndex(
      (f) => f === formData.report_entity_field,
    );
    const entities = excelData.rows
      .map((row) => row[index])
      .map((url) => ({ url }));

    const result = {
      name: '檢舉', // todo: check
      actions: [{
        type: 'batch.report',
        entities: entities,
        times: Number(data.times) || 1,
      }],
      trigger: {
        type: 'now',
      },
      accounts: data.accounts,
      tags: formData.tags,
    };

    if (data.times > data.accounts.length) {
      return setNotify({ open: true, message: '帳號號需大於檢舉次數' });
    }

    const res = await fetcher({
      url: `/users/${user?.id}/flows`,
      method: 'POST',
      data: result,
    });
    if (res?.data?.success) {
      setNotify({ open: true, message: '送出成功' });
      clearState();
      history.push('/app/batch-accounts');
    }
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextField
            variant="outlined"
            label="檢舉次數"
            type={'number'}
            defaultValue={1}
            name="times"
            onChange={({ target }) => onChange('times', target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Grid item xs={12} lg={12}>
            <AccountList
              title="帳號列表"
              expandableRows={false}
              selectable={true}
              onSelect={handleAccountsSelected}
              tagSetting={false}
              options={{
                filter: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={{ mr: 1 }}
        >
          送出
        </Button>
      </Box>
    </Box>
  );
};

export default Step3;

import React from 'react';

import DataTable from '@components/core/DataTable';

export default function FacebookInfo({ groups = [] }) {
  const columns = [
    {
      name: 'group_name',
      label: '社團名稱',
      options: {
        filter: false,
      }
    },
    {
      name: 'group_id',
      label: '連結',
      options: {
        customBodyRender: (value) => <a href={`https://facebook.com/groups/${value}`} target="_blank">{ `https://facebook.com/groups/${value}` }</a>,
        filter: false,
      }
    },
  ]

  return (
    <DataTable
      title="已加入社團列表"
      data={groups}
      columns={columns}
    />
  );
}

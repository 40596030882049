import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Fab,
  TextField,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import fetcher from '@/libs/fetcher';
import Select from '@components/core/Select';

function ColorPicker({ onChange, selectedColor }) {
  const COLORS = [
    '#eb5a46',
    '#ff9f1a',
    '#f2d600',
    '#61bd4f',
    '#51e898',
    '#00c2e0',
    '#0079bf',
    '#ff78cb',
    '#c377e0',
  ];
  const [selected, setSelected] = useState(COLORS[0]);

  function handleClick(color) {
    setSelected(color);
    onChange(color);
  }

  useEffect(() => {
    setSelected(selectedColor);
  }, [selectedColor]);

  return (
    <>
      {COLORS.map((color) => (
        <Fab
          key={color}
          className="mx-1"
          size="small"
          // sx={{ bgcolor: color }}
          style={{
            backgroundColor: color,
          }}
          onClick={() => handleClick(color)}
        >
          {selected === color && <CheckIcon />}
        </Fab>
      ))}
    </>
  );
}

export default function Modal({ handleClose, updateList, data, user, allTargets }) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: data?.name || '',
      color: data?.color || '#eb5a46',
      type: data?.type || 1,
      targets: data?.targets || [],
    },
  });

  const type = watch('type');
  const isEdit = !!data;

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('color', data.color);
      setValue('type', data.type);
      setValue('targets', data.targets);
    }
  }, [getValues, data, setValue]);

  const handleSetKeywords = (value) => {
    const targets = value.split(',').map((str) => str.trim());
    setValue('targets', targets);
  }

  const onSubmit = async (formData) => {
    let res;
    const result = {
      ...formData,
      type: Number(formData.type),
    };
    if (isEdit) {
      res = await fetcher({
        url: `/users/${user?.id}/tags/${data.id}`,
        method: 'PATCH',
        data: result,
      });
    } else {
      res = await fetcher({
        url: `/users/${user?.id}/tags`,
        method: 'post',
        data: result,
      });
    }
    if (res.data?.success) {
      handleClose();
      updateList();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{ required: '必填' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  margin="normal"
                  label="標籤名稱"
                  autoFocus
                  variant="outlined"
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  fullWidth
                  size="small"
                  label="標籤類型"
                  variant="outlined"
                  inputProps={field}
                  SelectProps={{
                    native: true,
                    inputProps: field,
                  }}
                  onChange={({ target: { value } }) => setValue('type', Number(value))}
                >
                  {/* <option value={0}>任務</option> */}
                  <option value={1}>帳號</option>
                  <option value={2}>好友標籤</option>
                </TextField>
              )}
            />
          </Grid>

          {
            type === 1 &&
            <Grid item xs={12}>
                <Controller
                  name="targets"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        variant="outlined"
                        label="綁定目標清單"
                        options={allTargets.map((t) => ({
                          name: t.name,
                          value: t.id,
                        }))}
                        multiple
                        value={value}
                        onChange={onChange}
                      />
                    )
                  }}
                ></Controller>
            </Grid>
          }

          {
            type === 2 &&
            <Grid item xs={12}>
                <Controller
                  name="targets"
                  control={control}
                  render={({ field }) => {
                    return (
                    <TextField
                      fullWidth
                      label="好友標籤關鍵字"
                      variant="outlined"
                      inputProps={field}
                      SelectProps={{
                        native: true,
                        inputProps: field,
                      }}
                      onChange={({ target: { value } }) => handleSetKeywords(value)}
                    >
                    </TextField>
                  )}}
                ></Controller>
            </Grid>
          }

          <Grid item xs={12}>
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <>
                  <Typography variant="body2" className="my-2">
                    標籤顏色
                  </Typography>
                  <ColorPicker
                    selectedColor={getValues('color')}
                    onChange={(color) => {
                      setValue('color', color);
                    }}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              // disabled={!isDirty || !isValid}
            >
              送出
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Stepper, Step, StepButton } from '@material-ui/core';
import Info from '@components/core/Info';
import Step1 from './Step1';
import Step2 from './Step2';
import YTStep2 from './YTStep2';
import Step3 from './Step3';

import { useUserState } from '@/context/UserContext';
import { add as addFlow } from '@/services/flows';

const steps = ['流程設定', '各別設定', '確認流程'];

export default function BatchStepper() {
  const { user } = useUserState();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [flow, setFlow] = useState({});
  const [settings, setSettings] = useState([]);
  const [notify, setNotify] = useState({ open: false, message: '' });

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    const newActiveStep = isLastStep()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setCompleted(newCompleted);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    const newCompleted = completed;
    newCompleted[activeStep - 1] = false;
    setCompleted(newCompleted);
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = () => {
    const accIDs = settings
      .map(setting => setting.accounts || [])
      .reduce((a, b) => a.concat(b))
      .map(acc => acc.id);
    const actions = settings
      .map(setting => setting.actions || [])
      .reduce((a, b) => a.concat(b))

    return addFlow(user.id, { ...flow, accounts: accIDs }, actions)
      .then((_) => {
        setNotify({ open: true, message: '新增成功' });
        history.push('/app/flows');
      })
      .catch((e) => setNotify({ open: true, message: '新增失敗' }));
  }

  const stepsContent = {
    0: <Step1 handleNext={handleNext} onNext={setFlow} />,
    1: flow.platform === 'facebook'
      ? <Step2 handleNext={handleNext} handleBack={handleBack} onNext={setSettings} flow={flow} />
      : <YTStep2 handleNext={handleNext} handleBack={handleBack} onNext={setSettings} flow={flow} />,
    2: <Step3 flow={flow} settings={settings} onOK={handleSubmit} handleBack={handleBack} />
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" disabled>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>{stepsContent[activeStep]}</div>
    </Box>
  );
}

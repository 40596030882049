import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  camera: {
    cursor: 'pointer',
  }
}));

export default function BadgeAvatars({ src, disabled, onChange }) {
  const classes = useStyles();

  const triggerUpload = () => {
    if (!disabled) {
      document.getElementById('fileupload').click()
    }
  }

  const handleUpload = (file) => {
    if (!disabled && onChange) {
      onChange(file)
    }
  }

  return (
    <div className={classes.root}>
      <Badge
        className={classes.camera}
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<PhotoCameraIcon />}
        onClick={triggerUpload}
      >
        <input
          type="file"
          id="fileupload"
          hidden
          onChange={e => handleUpload(e.target.files[0])}
        /> 
        <Avatar src={src} />
      </Badge>
    </div>
  );
}

import { authPost } from './commons';

function batchUpdateTags(user_id, ids, tags) {
  const payload = {
    ids,
    tags: tags.map(id => ({ id }))
  }

  return authPost(`/api/v1/users/${user_id}/accounts`, payload, 'PATCH');
}

export { batchUpdateTags }; 

import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';

export default function Modal({
  handleClose,
  handleOnSubmit = () => {},
  data
}) {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      content: data?.content || '',
    },
  });

  useEffect(() => {
    if (data) {
      setValue('content', data.content);
    }
  }, [getValues, data, setValue]);

  const onSubmit = (formData) => {
    handleOnSubmit(formData)
    handleClose()
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Controller
              name="content"
              control={control}
              rules={{ required: '必填' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  label="內容"
                  autoFocus
                  variant="outlined"
                  inputProps={field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              送出
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

import useSWR from 'swr';
import fetcher from '@/libs/fetcher';
import popup from '@components/core/Popup';
import { useUserState } from '@/context/UserContext';

export default function useTargets() {
  const { user } = useUserState()
  const id = user?.id
  const { data, error, mutate } = useSWR(id ? `/users/${user.id}/targets` : null, (url) =>
    fetcher({ url, needToken: true })
  )

  function remove(mid) {
    fetcher({
      url: `/users/${id}/targets/${mid}`,
      method: 'DELETE',
      needToken: true
    })
    .then(_ => mutate())
    .catch(_ => {
      popup({
        title: '錯誤',
        content: '無法刪除此素材，使用者僅能刪除自己的創建的素材'
      })
    })
  }

  function create(formData) {
    const doc = {
      name: formData.name,
      url: formData.url,
      platform: {
        type: formData.platform,
        page_type: formData.type,
      }
    }

    fetcher({
      url: `/users/${id}/targets`,
      method: 'POST',
      data: doc,
      needToken: true,
    })
    .then(_ => mutate())
  }

  function update(id, doc) {
    fetcher({
      url: `/users/${id}/targets/${id}`,
      method: 'PATCH',
      data: doc,
      needToken: true,
    })
    .then(_ => mutate())
  }

  return {
    targets: data?.data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
    remove,
    create,
    update,
  }
}

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

//import useAccount from '@hooks/useAccount';
import useTargets from '@/hooks/useTargets';
import Select from '@components/core/Select';
import SearchSelect from '@components/core/SearchSelect';
import { TARGET_TYPE } from './constants';

import Setting from './Setting';
import useStyles from './styles';

const initData = {
  url: '',
  target_type: '',
  target_id: '',
}

const Step2 = ({ flow, handleNext, handleBack, onNext }) => {
  const [done, setDone] = useState(false);
  const [data, setData] = useState(initData);
  const [settings, setSettings] = useState([]);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isAbleToAdd, setIsAbleToAdd] = useState(false);
  const { targets } = useTargets();
  const classes = useStyles();

  const fbGroups = targets
    .filter(t => t?.platform?.type === 'facebook' && t?.platform?.page_type === 'GROUP')
    .map(g => ({ name: g.name, id: g.url.split('/').pop(), url: g.url }))
  const fbPages = targets
    .filter(t => t?.platform?.type === 'facebook' && t?.platform?.page_type === 'PAGE')
    .map(g => ({ name: g.name, value: g.url }))

  useEffect(() => {
    if (
      settings.length > 0 &&
      settings.every(setting => setting.accounts && setting.accounts.length > 0)
    ) {
      setDone(true)
    }

    if (settings.length === 0) {
      setDone(false)
    }
  }, [settings, setDone])
  useEffect(() => {
    if (data.target_type === 'self') {
      return setIsAbleToAdd(true)
    }
    if (data.target_type === 'group' || ['add.friend', 'join.group', 'join.page'].includes(flow.action)) {
      if (data.target_id !== '') {
        return setIsAbleToAdd(true)
      }
    }
    if (data.target_type !== 'group' && data.target_type !== 'self') {
      if (data.url !== '') {
        return setIsAbleToAdd(true)
      }
    }

    return setIsAbleToAdd(false)
  }, [data, flow.action])

  const handleOnNext = () => {
    onNext(settings)
    handleNext()
  }

  const handleSetUrl = (e) => {
    setData({ ...data, url: e.target.value })
  }

  const handleSetTargetType = (e) => {
    setData({ ...data, target_type: e.target.value })
  }

  const handleSetTargetID = (e) => {
    setData({ ...data, target_id: e.target.value })
  }

  const handleAddData = () => {
    setData(initData)
    setSettings(
      [
        ...settings,
        { post_url: data.url, target_type: data.target_type, target_id: data.target_id },
      ]
    )
  }

  const handleSettingChange = (idx) => (setting) => {
    settings[idx] = setting
    setSettings([...settings])
  }

  const handleSettingDelete = (idx) => () => {
    const newSettings = settings.map((s, i) => i === idx ? null : s).filter(s => !!s)
    setSettings(newSettings)
  }

  const handdleStatusChange = (setting) => (status) => {
    if (status === '') {
      return setPreviewUrl('')
    }

    setDone(false)
    setPreviewUrl(setting.post_url)
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Grid container spacing={3}>
        {
          flow.action === 'create.post' &&
          <>
            <Grid item xs={3}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="頁面種類"
                name="target_type"
                value={data.target_type}
                options={TARGET_TYPE.filter(t => t.value === 'self' || t.value === 'group')}
                onChange={handleSetTargetType}
              />
            </Grid>
            {
              data.target_type === 'group' &&
              <Grid item xs={6}>
                <SearchSelect
                  className={classes.formItem}
                  variant="outlined"
                  label="社團ID"
                  name="group"
                  options={fbGroups.map(g => ({ ...g, value: g.id }))}
                  value={data.target_id}
                  onChange={handleSetTargetID}
                />
              </Grid>
            }
          </>
        }
        {
          !['create.post', 'add.friend', 'join.page', 'join.group'].includes(flow.action) &&
          <Grid item xs={9}>
            <TextField
              className={classes.formItem}
              variant="outlined"
              label="目標網址*"
              onChange={handleSetUrl}
              value={data.url}
            />
          </Grid>
        }
        {
          flow.action === 'join.group' &&
          <Grid item xs={6}>
            <SearchSelect
              className={classes.formItem}
              variant="outlined"
              label="社團網址"
              name="group"
              options={fbGroups.map(g => ({ ...g, value: g.url }))}
              value={data.url}
              onChange={handleSetUrl}
            />
          </Grid>
        }
        {
          flow.action === 'join.page' &&
          <Grid item xs={6}>
            <SearchSelect
              className={classes.formItem}
              variant="outlined"
              label="粉絲專頁網址"
              name="page"
              options={fbPages}
              value={data.url}
              onChange={handleSetUrl}
            />
          </Grid>
        }
        {
          flow.action === 'add.friend' &&
          <Grid item xs={9}>
            <TextField
              className={classes.formItem}
              variant="outlined"
              label="目標ID"
              onChange={handleSetTargetID}
              value={data.target_id}
            />
          </Grid>
        }
        <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleAddData}
            disabled={!isAbleToAdd}
          >
            新增
          </Button>
        </Grid>
        <Grid item xs={8}>
          {
            settings.map((setting, idx) => 
              <>
                {/* <Grid item xs={12}> */}
                  <Setting
                    key={idx}
                    idx={idx+1}
                    flow={flow}
                    setting={setting}
                    onSettingChange={handleSettingChange(idx)}
                    onSettingDeleted={handleSettingDelete(idx)}
                    onStatusChange={handdleStatusChange(setting)}
                  />
                {/* </Grid> */}
              </>
            )
          }
        </Grid>
        <Grid item xs={4} style={{ marginTop: 4 }}>
          {
            previewUrl !== '' &&
              <iframe
                src={`https://www.facebook.com/plugins/${previewUrl.includes('post') ? 'post' : 'page'}.php?href=${previewUrl}&tabs=timeline&adapt_container_width=true&hide_cover=false&show_facepile=true&appId&small_header=false&&width=350&height=500`}
                title="facebook"
                width="350"
                height="500"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
              </iframe>
          }
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginTop: 32 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          style={{ marginRight: 12 }}
        >
          上一步
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnNext}
          disabled={!done}
        >
          下一步
        </Button>
      </Box>
    </Box>
  );
};

export default Step2;

import React, { useState, useEffect } from 'react';
import useTags from '@hooks/useTags';
import {
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Divider,
} from '@material-ui/core';
import { Label as LabelIcon } from '@material-ui/icons';

const TAG_TYPES = {
  1: '帳號',
  2: '好友標籤',
}

export default function TagMenu({
  tags = [],
  onConfirm = () => console.log,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTags, setSelectedTags] = useState([])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTags([]);
  };

  const handleSelect = (id) => ({ target }) => {
    if(target.checked) {
      setSelectedTags([ ...selectedTags, id ])
    } else {
      setSelectedTags(selectedTags.filter(tag => tag !== id))
    }
  }

  const handleApply = () => {
    onConfirm(selectedTags);
    handleClose();
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <LabelIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          tags.map(tag => (
            <MenuItem>
              <Checkbox
                checked={selectedTags.includes(tag.id)}
                onChange={handleSelect(tag.id)}
              />
              { tag.name } ({ TAG_TYPES[tag.type] })
            </MenuItem>
          ))
        }
        <Divider />
        <MenuItem style={{ paddingLeft: 36 }} onClick={handleApply}>套用</MenuItem>
      </Menu>
    </>
  );
}

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Paper, Button, IconButton } from '@material-ui/core';
import {
  Add as AddIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

import { add as addMaterial } from '@/services/materials';
import { upload } from '@/services/files';

import { useUserState } from '@/context/UserContext';
import Select from '@components/core/Select';
import PageTitle from '@components/core/PageTitle';
import Info from '@components/core/Info';
import FileUploader from '@components/core/FileUploader';

import useStyles from './styles';
import { MATERIAL_NAME } from './constants';

const initialValues = {
  name: '',
  type: '',
  content: '',
};

const TYPES = Object.keys(MATERIAL_NAME)
  .map(value => ({
    name: MATERIAL_NAME[value],
    value,
  }))

export default function FlowsForm() {
  const classes = useStyles();
  const { user } = useUserState();
  const history = useHistory();
  const [notify, setNotify] = useState({ open: false, message: '' });
  const [type, setType] = useState('');
  const [numberOfForms, setNumberOfForms] = useState(1);
  const [forms, setForms] = useState([]);
  const [fileMeta, setFileMeta] = useState({});

  const {
    handleSubmit,
    control,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  });

  const onSubmit = () => {
    const data = getValues();

    addMaterial(user?.id, { ...data, forms, file: fileMeta })
      .then((_) => {
        setNotify({ open: true, message: '新增成功' });
        history.push('/app/materials');
      })
      .catch((e) => setNotify({ open: true, message: '新增失敗' }));
  };

  const handleChangeForms = (idx) => ({ target }) => {
    forms[idx] = target.value
    setForms(forms)
  }

  const handleMinusForms = () => {
    if (numberOfForms === 1) {
      return
    }
    setNumberOfForms(numberOfForms - 1)
  }

  const handleAddForms = () => {
    setNumberOfForms(numberOfForms + 1)
  }

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    upload(formData)
      .then((data) => setFileMeta(data.payload))
      .then(_ => setNotify({ open: true, message: '上傳檔案成功' }))
      .catch(e => setNotify({ open: true, message: '上傳檔案失敗' }))
  };

  return (
    <>
      <PageTitle title="新增素材" />
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={0} className={classes.layoutContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      className={classes.formItem}
                      variant="outlined"
                      label="名稱"
                      inputProps={field}
                    />
                  );
                }}
              ></Controller>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className={classes.formItem}
                    variant="outlined"
                    label="素材種類"
                    name="type"
                    inputProps={value}
                    onChange={({ target }) => {
                      setType(target.value);
                      onChange(target.value);
                    }}
                    options={TYPES}
                  />
                )}
              ></Controller>
            </Grid>
            {
              !['join_group_forms', 'image', 'video'].includes(type) &&
              <Grid item xs={12} lg={12}>
                <Controller
                  name="content"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      className={classes.formItem}
                      variant="outlined"
                      label="內容"
                      name="content"
                      multiline
                      rows={4}
                      inputProps={field}
                    />
                  }
                ></Controller>
              </Grid>
            }
            {
              ['image', 'video'].includes(type) &&
              <Grid item xs={12} lg={12}>
                <FileUploader onChange={handleUploadFile} width="100%" />
              </Grid>
            }
            {
              ['join_group_forms'].includes(type) &&
              <>
              {
                Array(numberOfForms).fill(0).map((_, i) =>
                  <Grid item xs={12} lg={12} key={i}>
                    <TextField
                      className={classes.formItem}
                      variant="outlined"
                      label={`問題-${i+1}`}
                      name={`content-${i+1}`}
                      multiline
                      rows={4}
                      onChange={handleChangeForms(i)}
                    />
                  </Grid>
                )
              }
                <Grid item xs={12} lg={12}>
                  <IconButton
                    variant="contained"
                    onClick={handleMinusForms}
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    onClick={handleAddForms}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </>
            }
            <Grid item xs={12} lg={12}>
              <div className={classes.floatRight}>
                <Button
                  variant="contained"
                  className={classes.mr}
                  onClick={history.goBack}
                >
                  取消
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  //disabled={!isDirty || !isValid}
                  //onClick={history.goBack}
                >
                  確定
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
}

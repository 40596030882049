export const RELATIONSHIP_OPTIONS = [
  { name: '單身', value: 'single' },
  { name: '穩定交往中', value: 'in a relationship' },
  { name: '同居', value: 'in a domestic partnership' },
  { name: '分居', value: 'separated' },
  { name: '守寡', value: 'widowed' },
  { name: '離婚', value: 'divorced' },
  { name: '已婚', value: 'married' },
  { name: '訂婚', value: 'engaged' },
  { name: '難以言喻', value: "it's complicated" },
]

export const PRIVACY_OPTIONS = [
  { name: '公開', value: "public" },
  { name: '只限好友', value: "friends" },
  { name: '只有我自己', value: "only_me" },
]

import React from 'react';
import { Grid } from '@material-ui/core';
import PageTitle from '@components/core/PageTitle';
import AccountList from '@components/shared/AccountList';

export default function Tables() {
  return (
    <>
      <PageTitle
        title="帳號管理"
        // button={<button onClick={reloadPage}>reload icon</button>}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AccountList
            selectable={true}
            disableToolbarSelect={false}
          />
        </Grid>
      </Grid>
    </>
  );
}

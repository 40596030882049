import { useMemo } from 'react';
import { Paper, Typography, LinearProgress } from '@material-ui/core';
import Chart from '@components/core/Chart';

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default function TasksByTag(props) {
  const { data, tags } = props

  if (!data) {
    return (
      <>
        <Typography variant="h6" color="text">
          各任務類別完成
        </Typography>
        <Paper elevation={0}>
          <LinearProgress />
        </Paper>
      </>
    )
  }

  const groupedData = groupBy(
   (data?.tasks_by_tag || [])
      .map(row => ({ ...row, tag: tags.find(t => t.id === row.tag)?.name || row.tag })),
    'tag'
  )
  const sourceData = Object.keys(groupedData)
    .map(tag => {
      const counter = { tag, DONE: 0, UNDONE: 0 }
      groupedData[tag].forEach(row => {
        row.status === 'DONE'
          ? counter['DONE'] += row.count
          : counter['UNDONE'] += row.count
      })

      return counter
    })

  const OPTION = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
    },
    legend: {
      data: ['DONE', 'UNDONE']
    },
    dataset: {
      dimensions: ['tag', 'DONE', 'UNDONE'],
      source: sourceData,
    },
    xAxis: { type: 'category' },
    yAxis: {},
    series: [
      {
        type: 'bar',
      },
      {
        type: 'bar',
      },
    ]
  }

  return (
    <>
      <Typography variant="h6" color="text">
        各任務類別完成
      </Typography>
      <Paper elevation={0}>
        <Chart
          option={OPTION}
          style={{
            height: '300px',
          }}
        />
      </Paper>
    </>
  );
}

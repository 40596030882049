import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Button,
  Grid,
  TextField,
  Checkbox,
  Badge,
  IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';

import Select from '@components/core/Select';
import popup from '@components/shared/Popup';

import { upload } from '@/services/files';
import useMaterials from '@/hooks/useMaterials';
import useStyles from './styles';
import { ACTION_NAME } from '@constants';

const initialValues = {
  react_type: 'like',
  subscribe_type: 'subscribe',
  content: '',
  title: '',
  privacy: 'public',
  datetime: dayjs().format('YYYY-MM-DD HH:mm'),
};
  
const Step3 = ({
  handleBack,
  flow,
  selectedAccs,
  onOk,
  reply,
  editables,
  handleTglEditables,
}) => {
  const [datas, setDatas] = useState(
    new Array(selectedAccs.length).fill({ ...initialValues }),
  );

  const handleDataChange = (idx) => (field, value) => {
    datas[idx] = { ...datas[idx], [field]: value };
    setDatas([...datas]);
  };

  const handleOnNext = () => {
    onOk({ editables, datas });
  };


  useEffect(() => {
    if (reply.length === 1) {
      handleDataChange(0)('content', reply[0]);
    }
    if (reply.length > 1) {
      for (let i = 0; i < editables.length; i++) {
        if (editables[i]) {
          handleDataChange(i)('content', reply[i]);
        }
      }
    }
  }, [reply]);

  return (
    <>
      <Box
        sx={{
          pt: 2,
          border: '1px solid #ccc',
          p: 2,
          borderRadius: 4,
          position: 'relative',
        }}
      >
        <h3
          style={{
            margin: 0,
            position: 'absolute',
            top: -12,
            background: '#fff',
            padding: '0 8px',
          }}
        >
          {ACTION_NAME[flow.action]}
        </h3>
        {selectedAccs.map((acc, i) => (
          <ContentSetting
            key={acc}
            idx={i}
            editable={editables[i]}
            flow={flow}
            account={acc}
            onEditChange={handleTglEditables(i)}
            data={datas[i]}
            onDataChange={handleDataChange(i)}
            groups={acc.groups}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          style={{ marginRight: 12 }}
        >
          上一步
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnNext}
          sx={{ mr: 1 }}
        >
          確定
        </Button>
      </Box>
    </>
  );
};

function ContentSetting({ flow, account, idx, editable, onEditChange, data, onDataChange }) {
  const classes = useStyles();

  const { materials } = useMaterials(['image', 'video', 'join_group_forms']);
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [file, setFile] = useState(null);


  const action = flow.action;
  const trigger = flow.trigger;

  const handleTglEditable = ({ target }) => {
    onEditChange(target.checked)
  }

  const handleValueChange = (field) => ({ target }) => {
    onDataChange(field, target.value)
  }

  const handleDatetimeChange = (m) => {
    onDataChange('datetime', m.format('YYYY-MM-DD HH:mm:ss'))
  }

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file)

    upload(formData)
      .then(data => {
        setFile(data.payload)
        setSelectedMaterial('')
        popup({
          title: '檔案上傳成功'
        });
      })
  }

  const handleSelectMaterial = ({ target }) => {
    setFile(null)
    setSelectedMaterial(target.value)
  }

  const handleRemoveMaterial = () => {
    setFile(null)
    setSelectedMaterial('')
  }

  useEffect(() => {
    if (file) {
      return onDataChange('material', { url: `/files/${file.filename}`, path: file.path })
    }

    if (selectedMaterial !== '') {
      const m = materials.find(m => m.id === selectedMaterial)
      const filename = m?.contents[0]?.source?.filename
      const path = m?.contents[0]?.source?.path

      onDataChange('material', { url: `/files/${filename}`, path: path })
    }

    if (!file && selectedMaterial === '') {
      onDataChange('material', null)
    }

  }, [selectedMaterial, file])

  return (
    <Paper  elevation={0} style={{marginTop: 12}}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <h2 style={{margin: '0', fontSize: 28, fontWeight: '400'}}>{ account.name }</h2>
          {
            idx > 0 && <div style={{display: 'flex', alignItems: 'center'}}><Checkbox checked={!editable} onChange={handleTglEditable} />與第一則相同</div>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} style={{ paddingTop: 12 }}>
          {
            trigger === 'schedule' && editable &&
            <Grid item xs={11}>
              <MuiPickersUtilsProvider locale="en" utils={DayJsUtils}>
                <DateTimePicker
                  label="開始日期"
                  format="YYYY-MM-DD HH:mm"
                  className={classes.formItem}
                  value={data.datetime}
                  onChange={handleDatetimeChange}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          {
            ['yt.create.video'].includes(action) && editable &&
            <Grid item xs={12}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                label="標題"
                name="title"
                value={data.title}
                onChange={handleValueChange("title")}
                multiline
                rows={1}
              />
            </Grid>
          }
          {
            ['yt.create.video', 'yt.comment.video', 'yt.reply.comment'].includes(action) && editable &&
            <Grid item xs={12}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                label="內容"
                name="content"
                value={data.content}
                onChange={handleValueChange("content")}
                multiline
                rows={4}
              />
            </Grid>
          }
          {
            action === 'yt.like.video' && editable &&
            <Grid item xs={11}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="按讚/不喜歡"
                name="react_type"
                options={[{name: "按讚", value: "like" }, { name: '不喜歡', value: 'dislike' }]}
                value={data.react_type}
                onChange={handleValueChange("react_type")}
              />
            </Grid>
          }
          {
            action === 'yt.subscribe.channel' && editable &&
            <Grid item xs={11}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="訂閱/取消訂閱"
                name="subscribe_type"
                options={[{name: "訂閱", value: "subscribe" }, { name: '取消訂閱', value: 'unsubscribe' }]}
                value={data.subscribe_type}
                onChange={handleValueChange("subscribe_type")}
              />
            </Grid>
          }
          {
            action === 'yt.create.video' && editable &&
            <Grid item xs={12}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="隱私設定"
                name="privacy"
                options={[
                  { name: "公開", value: "public" },
                  { name: '未公開', value: 'private' },
                  { name: 'unlisted', value: 'unlisted' }
                ]}
                value={data.privacy}
                onChange={handleValueChange("privacy")}
              />
            </Grid>
          }
          {
            action === 'yt.create.video' && editable &&
            <>
                <Grid item xs={6} lg={2} style={{display: 'flex', alignItems: 'center'}}>
                  <Button
                    variant="contained"
                    component="label"
                  >
                    上傳檔案
                    <input
                      type="file"
                      accept="video/*"
                      hidden
                      onChange={e => handleUpload(e.target.files[0])}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Select
                    className={classes.formItem}
                    variant="outlined"
                    label="從素材庫中選取"
                    options={
                      materials
                        .filter(m => m.type === 'video')
                        .map(m => ({ name: m.name, value: m.id }))
                    }
                    value={selectedMaterial}
                    onChange={handleSelectMaterial}
                  />
                </Grid>
                {
                  data?.material?.url &&
                  <Grid item xs={6} lg={4}>
                    <Badge badgeContent={<IconButton onClick={handleRemoveMaterial}><DeleteIcon /></IconButton>}>
                      <img width="100%" alt="預覽" src={data?.material?.url} />
                    </Badge>
                  </Grid>
                }
            </>
          }
        </Grid>
      </Grid>
    </Grid>
    </Paper>
  )
}

export default Step3;

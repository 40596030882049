import React from 'react';
import { useTheme } from '@material-ui/styles';
import classnames from 'classnames';
import dayjs from 'dayjs';

// styles
import useStyles from './styles';

// components
import { Typography } from '../../../../Wrappers';

export default function Notification({ variant, ...props }) {
  var classes = useStyles();
  var theme = useTheme();

  return (
    <div
      className={classnames(classes.notificationContainer, props.className, {
        [classes.notificationContained]: variant === 'contained',
        [classes.notificationContainedShadowless]: props.shadowless,
      })}
      style={{
        backgroundColor:
          variant === 'contained' &&
          theme.palette[props.color] &&
          theme.palette[props.color].main,
      }}
    >
      <div className={classes.messageContainer}>
        <Typography
          className={classnames({
            [classes.containedTypography]: variant === 'contained',
          })}
          variant={props.typographyVariant}
          size={variant !== 'contained' && !props.typographyVariant && 'md'}
        >
          <p>{ props.content }</p>
          <p style={{ color: '#aaa' }}>{ dayjs.unix(props.created_at).format('YYYY-MM-DD HH:mm') }</p>
        </Typography>
        { props.extraButton && props.extraButton }
      </div>
    </div>
  );
}

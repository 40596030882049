import React from 'react';
import {
  Grid,
  Box,
  Button,
  TextField,
  Paper,
} from '@material-ui/core';

import Select from '@components/core/Select';
import AccountList from '@components/shared/AccountList';
import useTags from '@hooks/useTags';

import useStyles from './styles';
import AccountTasks from './AccountTasks.js';
import { TRIGGER_CONDITIONS, ACTION_TYPE, YT_ACTION_TYPE } from './constants';

const Step3 = ({ flow, settings, handleBack, onOK }) => {
  const { tags } = useTags('task');
  const classes = useStyles();

  const accountActions = settings
    .map(setting => {
      return setting.accounts.map((acc, i) => ({ account_id: acc.id, action: setting.actions[i] }))
    })
    .reduce((a, b) => a.concat(b))
  const allAccounts = settings
    .map(setting => setting.accounts)
    .reduce((a, b) => a.concat(b))
    .map(acc => acc.id)

  return (
    <>
      <form>
        <Paper elevation={0} className={classes.layoutContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                label="名稱*"
                value={flow.name}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="觸發條件*"
                value={flow.trigger}
                options={TRIGGER_CONDITIONS}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="動作*"
                name="action"
                value={flow.action}
                options={flow.platform === 'youtube' ? YT_ACTION_TYPE : ACTION_TYPE}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="任務標籤"
                options={tags.map((tag) => ({
                  name: tag.name,
                  value: tag.id,
                }))}
                multiple
                value={flow.tags}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                label="備註"
                name="description"
                multiline
                rows={4}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <AccountList
                title="參與帳號列表"
                expandableRows={true}
                renderExpandableRow={(values) =>
                  <AccountTasks id={values[1]} actions={accountActions} />
                }
                selectable={false}
                filter={acc => allAccounts.includes(acc.id)}
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          style={{ marginRight: 12 }}
        >
          上一步
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onOK}
        >
          送出
        </Button>
      </Box>
    </>
  );
};

export default Step3;

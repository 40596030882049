import React, { useEffect } from 'react';
import { TableCell, TableRow, IconButton } from '@material-ui/core';
import {
  CallMade as CallMadeIcon,
} from '@material-ui/icons';
import DataTable from '@components/core/DataTable';
import TaskActions from '@components/shared/TaskActions';
import useAPI from '@/services/api';
import { ACTION_NAME, formatUrl } from '@/constants';
import useStyles from '../styles';

export default function FlowTasks({ id }) {
  const classes = useStyles();

  const { data: tasks, update, reload } = useAPI(`/flows/${id}/tasks`);
  const done = tasks.every((t) => t.status === 'DONE' || t.status === 'FAIL');

  useEffect(() => {
    if (done) {
      return;
    }

    const id = setInterval(reload, 5000);
    return () => clearInterval(id);
  }, [done, reload]);

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'account_name',
      label: '帳號',
    },
    {
      name: 'type',
      label: '動作',
      options: {
        customBodyRender: (action) => ACTION_NAME[action] || action,
      },
    },
    {
      name: 'url',
      label: '目標連結',
      options: {
        customBodyRenderLite: (idx) => formatUrl(tasks[idx]),
      },
    },
    {
      name: 'created_at',
      label: '開始時間',
      options: {
        customBodyRenderLite: (idx) =>
          new Date(tasks[idx].created_at * 1000).toLocaleString(),
      },
    },
    {
      name: 'updated_at',
      label: '上次更新',
      options: {
        customBodyRenderLite: (idx) =>
          tasks[idx].updated_at &&
          new Date(tasks[idx].updated_at * 1000).toLocaleString(),
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        customBodyRender: (status) => (
          <span className={classes[status]}>{status}</span>
        ),
      },
    },
    {
      name: 'action',
      label: '操作',
      options: {
        customBodyRenderLite: (idx) => (
          <>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              href={tasks[idx].post_url}
              target="_blank"
            >
              <CallMadeIcon
                classes={{
                  root: classes.headerIcon,
                }}
              />
            </IconButton>
            <TaskActions task={tasks[idx]} update={update} reload={reload} />
          </>
        ),
      },
    },
  ];

  return (
    <TableRow>
      <TableCell colSpan={8}>
        <DataTable title="任務列表" data={tasks} columns={columns} />
      </TableCell>
    </TableRow>
  );
}

import axios from 'axios';

const CUSTOMER_HEADERS = {
  'Time-Zone': 'GMT-0400',
  'Content-Type': 'application/json',
};

export default function fetcher({
  url,
  method = 'get',
  data,
  extraHeaders,
  needToken = true,
}) {
  const headers = { ...CUSTOMER_HEADERS, ...extraHeaders };
  if (needToken) {
    const token = localStorage.getItem('id_token');
    headers.Authorization = token;
    headers.ContentType = 'application/json';
  }

  return axios({
    headers,
    method,
    url: `/api/v1${url}`,
    data,
  });
}

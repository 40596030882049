import React, { useState } from 'react';
import useSWR from 'swr';
import { useHistory, Link } from 'react-router-dom';
import fetcher from '@/libs/fetcher';
import { Grid, Button, Chip } from '@material-ui/core';
import { useUserState } from '@/context/UserContext';
import DataTable from '@components/core/DataTable';
import PageTitle from '@components/core/PageTitle';
import Info from '@components/core/Info';
import useTags from '@hooks/useTags';

export default function BatchAccounts() {
  const { user } = useUserState();
  const history = useHistory();
  const { tags } = useTags('task');
  const [notify, setNotify] = useState({ open: false, message: '' });

  const { data: rowData } = useSWR(`/users/${user.id}/tasks/_search`, (url) =>
    fetcher({
      url,
      method: 'POST',
      data: { types: ['batch.report', 'report'], extra: ['result'] },
    }),
  );

  const { data: downloadFile } = useSWR(
    `/users/${user?.id}/tasks/_download?types=batch.report,report&extra=result`,
    (url) =>
      fetcher({
        url,
      }),
  );

  const columns = [
    {
      name: 'index',
      label: '項次',
      options: {
        filter: false,
        download: false,
        customBodyRenderLite: (idx) => <>{idx + 1}</>,
      },
    },
    {
      name: 'id',
      label: '項次',
      options: { display: false, filter: false, download: false },
    },
    {
      name: 'account_name',
      label: '帳號ID',
      options: {
        filter: false, //todo: api return account profile.name || profile.id
        customBodyRenderLite: (idx) => {
          const id = (rowData?.data || [])[idx]?.account_id;
          const name = (rowData?.data || [])[idx]?.account_name;

          return <Link to={`/app/accounts/${id}`}>{name}</Link>;
        },
      },
    },
    {
      name: 'platform',
      label: '平台',
      options: {
        download: false,
        customBodyRenderLite: (idx) => <>facebook</>,
      },
    },
    {
      name: 'tags',
      label: '標籤',
      options: {
        filter: true,
        filterOptions: {
          names: tags.map(t => t.id),
          renderValue: id => tags.find(t => t.id === id)?.name,
          logic: (tags, filters, row) => !tags.some(id => filters.includes(id))
        },
        customFilterListOptions: {
          render: (id) => '標籤: ' + tags.find(t => t.id === id)?.name,
        },
        download: false,
        customBodyRenderLite: (idx) => {
          const tagIDs = (rowData?.data || [])[idx]?.tags;

          return tagIDs.map((id) => (
            <Chip
              key={id}
              label={tags.find((t) => t.id === id)?.name}
              style={{
                backgroundColor: tags.find((t) => t.id === id)?.color,
                color: '#fff',
              }}
            />
          ));
        },
      },
    },
    {
      name: 'post_url',
      label: '檢舉URL',
      options: {
        filter: false,
        customBodyRender: (url) => url?.length >= 75
          ? <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#4a4a4a' }}>{ url.slice(0, 75) + '...' }</a>
          : <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#4a4a4a' }}>{ url }</a>
      },
    },
    {
      name: 'created_at',
      label: '檢舉時間',
      options: {
        filter: false,
        customBodyRender: (t) => new Date(t * 1000).toLocaleString(),
      },
    },
    {
      name: 'result',
      label: '上次檢查日',
      options: {
        filter: false,
        customBodyRender: (t) =>
          (t && t.tm_update && new Date(t.tm_update * 1000).toLocaleString()) ||
          '尚未檢查',
      },
    },
    {
      name: 'result',
      label: '現況',
      options: {
        customBodyRender: (t) =>
          'is_alive' in t ? (t.is_alive ? '存活' : '已下架') : '',
      },
    },
    // {
    //   name: 'assigner',
    //   label: '任務指派人',
    // },
  ];

  return (
    <>
      <PageTitle
        title="帳號檢舉紀錄"
        extra={
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push('/app/batch-accounts/create')}
          >
            上載檢舉清單
          </Button>
        }
      />
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            data={rowData?.data}
            columns={columns}
            options={{
              elevation: 0,
              download: true,
              onDownload: () => '\uFEFF' + downloadFile?.data,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

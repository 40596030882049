import React, { useState } from 'react';
import {
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

export default function MutilTextField({ label="問題", texts, onTextsChange }) {
  const handleChangeForms = (idx) => ({ target }) => {
    texts[idx] = target.value
    onTextsChange([ ...texts ])
  }

  const handleMinusForms = () => {
    if (texts.length === 1) {
      return
    }
    texts.pop()
    onTextsChange([...texts])
  }

  const handleAddForms = () => {
    onTextsChange([...texts, ''])
  }

  return (
    <Grid container spacing={4}>
    {
      texts.map((text, i) =>
        <Grid item xs={12} lg={12} key={i}>
          <TextField
            style={{ width: '100%' }}
            variant="outlined"
            label={`${label}-${i+1}`}
            name={`content-${i+1}`}
            multiline
            rows={4}
            value={text}
            onChange={handleChangeForms(i)}
          />
        </Grid>
      )
    }
      <Grid item xs={12} lg={12}>
        <IconButton
          variant="contained"
          onClick={handleMinusForms}
        >
          <ClearIcon />
        </IconButton>
        <IconButton
          variant="contained"
          onClick={handleAddForms}
        >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

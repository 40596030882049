import React from 'react';
import ReactDOM from 'react-dom';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

const popup = ({ title, content, onOk, onCancel }) =>
  new Promise((resolve, reject) => {
    const container = document.createElement(`popup-${new Date().getDate()}`);
    function destroyNode() {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(container);
      }, 100);
    }

    function handleClose() {
      destroyNode();
      resolve(null);
    }
    function handleOk() {
      destroyNode();
      resolve('ok');
      if (onOk) {
        onOk();
      }
    }
    function handleCancel() {
      destroyNode();
      resolve(null);
      if (onCancel) {
        onCancel();
      }
    }

    const contentWithProps = React.Children.map(content, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          handleOk,
          handleCancel,
          handleClose,
        });
      }
      return child;
    });

    ReactDOM.render(
      <React.StrictMode>
        <Dialog open onClose={handleCancel} onBackdropClick={handleCancel}>
          <DialogTitle> {title}</DialogTitle>
          <DialogContent>{contentWithProps}</DialogContent>
          <DialogActions>
            {onOk && (
              <Button onClick={handleOk} color="primary">
                送出
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </React.StrictMode>,
      document.body.appendChild(container),
    );
  });

export default popup;

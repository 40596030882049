import React from 'react';

import DataTable from '@components/core/DataTable';

export default function FacebookInfo({ friends = [] }) {
  const columns = [
    {
      name: 'friend_name',
      label: '名稱',
      options: {
        filter: false,
      }
    },
    {
      name: 'friend_id',
      label: '連結',
      options: {
        customBodyRender: (value) => <a href={`https://facebook.com/${value}`} target="_blank">{ `https://facebook.com/${value}` }</a>,
        filter: false,
      }
    },
  ]

  return (
    <DataTable
      title="好友清單"
      data={friends}
      columns={columns}
    />
  );
}

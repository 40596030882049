import { useState, useEffect } from 'react';
import { authGet, authPost, authDelete } from './commons';

//resource: path     ex. /users, /users/123/accounts
function useAPI(resource) {
  const [ data, setData ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(true);

  const prefix = '/api/v1';

  useEffect(() => {
    setIsFetching(true);

    authGet(prefix+resource)
      .then(docs => {
        setData(Array.isArray(docs) ? docs : [docs]);
        setIsFetching(false);
      })
      .catch(e => setIsFetching(false))

  }, [resource]);

  function reload() {
    setIsFetching(true);

    authGet(prefix+resource)
      .then(docs => {
        setData(Array.isArray(docs) ? docs : [docs]);
        setIsFetching(false);
      })
      .catch(e => setIsFetching(false))
  }

  function create(payload) {
    setIsFetching(true);

    authPost(prefix+resource, payload)
      .then(_ => {
        setData(data => ([ ...data, { ...payload, id: _.data.id }]))
        setIsFetching(false);
      })
      .catch(e => setIsFetching(false))
  }

  function update(id, payload, method = 'PATCH') {
    setIsFetching(true);

    return authPost(`${prefix}${resource}/${id}`, payload, method)
      .then(_ => {
        setData(data =>
          data.map(doc => doc.id === id
            ? { ...doc, ...payload }
            : doc
          )
        )
        setIsFetching(false);
      })
      .catch(e => setIsFetching(false))
  }

  function remove(id) {
    setIsFetching(true);

    authDelete(`${prefix}${resource}/${id}`)
      .then(_ => {
        setData(data => data.filter(d => d.id !== id))
        setIsFetching(false)
      })
      .catch(e => setIsFetching(false))
  }

  return { data, isFetching, reload, create, update, remove };
}

export default useAPI;

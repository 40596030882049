function login(email, password) {
  return fetch('/auth/login', {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(res => res.json())
}

export default login

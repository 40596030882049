import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  headerIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  ACTIVE: {
    color: '#536dfe',
  },
  DONE: {
    color: 'green',
  },
  FAIL: {
    color: 'red',
  },
}));

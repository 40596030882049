import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  layoutContainer: {
    padding: theme.spacing(3),
  },
  formItem: {
    width: '100%',
  },
  mr: {
    marginRight: 12,
  },
  floatRight: {
    float: 'right',
  }
}));

import React from 'react';
import { Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { Calendar, Views, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = dayjsLocalizer(dayjs);

const CustomToolbar = ({ onNavigate }) => {
  const handleTodayClick = () => {
    onNavigate('TODAY');
  };

  const handleBackClick = () => {
    onNavigate('PREV');
  };

  const handleNextClick = () => {
    onNavigate('NEXT');
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button className="rbc-btn" onClick={handleTodayClick}>
          Today
        </button>
        <button className="rbc-btn" onClick={handleBackClick}>
          Back
        </button>
        <button className="rbc-btn" onClick={handleNextClick}>
          Next
        </button>
      </span>
    </div>
  );
};

const CalendarBasic = ({ events = [], date, onNavigate, onRangeChange, EventComponent }) => {
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: '#fff',
      borderRadius: '5px',
      opacity: 0.8,
      color: '#fff',
      border: 'none',
      display: 'block',
      padding: '2px',
    };

    return {
      style,
    };
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Calendar
            localizer={localizer}
            events={events}
            date={date}
            onNavigate={onNavigate}
            onRangeChange={onRangeChange}
            style={{height: '100vh'}}
            view={Views.MONTH}
            views={[Views.MONTH]}
            eventPropGetter={eventStyleGetter}
            components={{event: EventComponent}}
            popup
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default CalendarBasic;

import React, { useState, useEffect } from 'react';
import { Chip, Checkbox, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import useAPI from '@/services/api';
import { batchUpdateTags } from '@/services/accounts';
import { useUserState } from '@/context/UserContext';
import useTags from '@hooks/useTags';
import DataTable from '@components/core/DataTable';
import AccountTasks from '@components/shared/AccountTasks';

import Tags from './components/Tags';
import TagMenu from './components/TagMenu';
import useStyles from './styles';

const PER_PAGE = 10;

const states = {
  active: 'success',
  pending: 'warning',
  died: 'secondary',
};

export default function Table({
  title,
  expandableRows = true,
  renderExpandableRow,
  selectable = false,
  disableToolbarSelect = true,
  elevation = 0,
  onSelect,
  tagSetting = false,
  filter = () => true,
  options,
}) {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isCurPageSelectedAll, setIsCurPageSelectedAll] = useState(false);
  const [displayIndexes, setDisplayIndexes] = useState([]);
  const { user } = useUserState();
  const { tags, updateSort } = useTags(['account', 'friend']);
  const {
    data,
    update,
    reload,
  } = useAPI(`/users/${user.id}/accounts`);
  const accounts = data.filter(filter);

  const handleUpdateTags = (idx, selectedTags) => {
    const tags = selectedTags.map((t) => ({ id: t }));

    update(accounts[idx].id, {
      tags: tags,
    });
    reload();
  };

  const handleSortTags = (fromTag, toTag) => {
    updateSort(fromTag, toTag)
  }

  const handleTagsConfirm = (tags) => {
    const accIDs = selectedRows.map((idx) => accounts[idx].id);

    batchUpdateTags(user.id, accIDs, tags)
      .then(_ => {
        setSelectedRows([]);
        reload();
      })
      .catch(e => {
        console.error(e.message)
      })
  }

  const handleSelect = (a, b, ids) => {
    setSelectedRows(ids);
  }

  const handleOnFilterChange = (eventName, state) => {
    const indexes = state.displayData.map((row) => row.dataIndex);
    if (displayIndexes.length !== indexes.length) {
      setDisplayIndexes(indexes)
    }
  }

  const handleSelectAll = ({ target }) => {
    if (target.checked) {
      setSelectedRows(displayIndexes)
    }
    if (!target.checked) {
      setSelectedRows([])
    }
  }

  const handleSelectCurPage = ({ target }) => {
    if (target.checked) {
      //merge alreay selected rows
      setSelectedRows(
        [...new Set([...selectedRows, ...displayIndexes.slice(page * PER_PAGE, (page + 1) * PER_PAGE)])]
      )
    }

    if (!target.checked) {
      setSelectedRows(selectedRows.filter(row => !displayIndexes.slice(page * PER_PAGE, (page + 1) * PER_PAGE).includes(row)))
    }
  }

  useEffect(() => {
    if (displayIndexes.every(row => selectedRows.includes(row))) {
      return setIsSelectedAll(true)
    }

    setIsSelectedAll(false)
  }, [selectedRows, page, accounts])

  useEffect(() => {
    if (displayIndexes.slice(page * PER_PAGE, (page + 1) * PER_PAGE).every(row => selectedRows.includes(row))) {
      return setIsCurPageSelectedAll(true)
    }

    setIsCurPageSelectedAll(false)
  }, [selectedRows, page, accounts])

  useEffect(() => {
    const rowData = selectedRows.map((idx) => accounts[idx]);
    if (onSelect) {
      onSelect(selectedRows, rowData);
    }
  }, [selectedRows])

  const columns = [
    {
      name: 'index',
      label: '項次',
      options: { filter: false, customBodyRenderLite: (idx) => <>{idx + 1}</> },
    },
    {
      name: 'id',
      options: { display: false, filter: false },
    },
    {
      name: 'name',
      label: '名稱',
      options: {
        filter: false,
        customBodyRenderLite: (idx) => (
          <Link target="_blank" to={`/app/accounts/${accounts[idx]['id']}`}>
            {accounts[idx]['name']}
          </Link>
        ),
      },
    },
    {
      name: 'platform',
      label: '平台',
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        customBodyRender: (v) => (
          <Chip
            label={v}
            classes={{ root: classes[states[v.toLowerCase()]] }}
          />
        ),
      },
    },
    {
      name: 'updated_at',
      label: '上次更新',
      options: {
        filter: false,
        customBodyRender: (t) => new Date(t * 1000).toLocaleString(),
      },
    },
    {
      name: 'level',
      label: '帳號等級',
      options: {
        customFilterListOptions: {
          render: level => '等級: ' + level,
        },
      },
    },
    {
      name: 'profile',
      label: '家鄉',
      options: {
        display: false,
        filter: true,
        filterOptions: {
          names: [ ...new Set(accounts.map(acc => acc?.profile?.hometown).filter(Boolean)) ],
          logic: (value, filters, row) => !filters.includes(value),
        },
        customFilterListOptions: {
          render: (text) => '家鄉: ' + text,
        },
        customBodyRender: (profile) => profile?.hometown || ''
      },
    },
    {
      name: 'profile',
      label: '居住地',
      options: {
        display: false,
        filter: true,
        filterOptions: {
          names: [ ...new Set(accounts.map(acc => acc?.profile?.current_city).filter(Boolean)) ],
          logic: (value, filters, row) => !filters.includes(value),
        },
        customFilterListOptions: {
          render: (text) => '居住地: ' + text,
        },
        customBodyRender: (profile) => profile?.current_city || ''
      },
    },
    {
      name: 'profile',
      label: '性別',
      options: {
        display: false,
        filter: true,
        filterOptions: {
          names: [ ...new Set(accounts.map(acc => acc?.profile?.gender).filter(Boolean)) ],
          logic: (gender, filters, row) => !filters.includes(gender),
        },
        customFilterListOptions: {
          render: (text) => '性別: ' + text,
        },
        customBodyRender: (profile) => profile?.gender || ''
      },
    },
    {
      name: 'profile',
      label: '年紀',
      options: {
        display: true,
        filter: true,
        filterOptions: {
          names: [ 10, 20, 30, 40, 50, 60 ],
          renderValue: v => v + '-' + (v+9),
          logic: (profile, filters, row) => {
            const year = dayjs(profile?.birthday || 'Jan 1, 1900').format('YYYY')
            const howOld = Number(dayjs().format('YYYY'))-Number(year)

            return !filters.some(v => howOld >= v && howOld <= v+9)
          }
        },
        customFilterListOptions: {
          render: (v) => '年紀: ' + v + '-' + (v+9),
        },
        customBodyRender: (profile) => {
          const year = dayjs(profile?.birthday || 'Jan 1, 1900').format('YYYY')

          return Number(dayjs().format('YYYY'))-Number(year)
        }
      },
    },
    {
      name: 'tags',
      label: '標籤',
      options: {
        filter: true,
        filterOptions: {
          names: tags.map(t => t.id),
          renderValue: id => tags.find(t => t.id === id)?.name,
          logic: (tags, filters, row) => !tags.map(t => t.id).some(id => filters.includes(id))
        },
        customFilterListOptions: {
          render: (id) => '標籤: ' + tags.find(t => t.id === id)?.name,
        },
        customBodyRenderLite: (idx) => (
          <Tags
            tags={tags}
            defaultTags={accounts[idx].tags}
            handleUpdateTags={(value) => handleUpdateTags(idx, value)}
            handleSortTags={handleSortTags}
            tagSetting={tagSetting}
          />
        ),
      },
    },
  ];

  function SelectAllCheckbox() {
    if (!selectable) {
      return null
    }

    return (
      <>
        <Tooltip title="選擇當前頁面">
          <Checkbox checked={isCurPageSelectedAll} onChange={handleSelectCurPage} />
        </Tooltip>
        <Tooltip title="選擇全部">
          <Checkbox checked={isSelectedAll} onChange={handleSelectAll} />
        </Tooltip>
      </>
    )
  }

  return (
    <DataTable
      title={title}
      data={accounts}
      columns={columns}
      options={{
        elevation,
        filterArrayFullMatch: false,
        expandableRows,
        onRowSelectionChange: handleSelect,
        renderExpandableRow: (values) => {
          if (renderExpandableRow) {
            return renderExpandableRow(values)
          }

          return <AccountTasks id={values[1]} />
        },
        rowsSelected: selectedRows,
        selectableRows: selectable ? 'multiple' : 'none',
        filterType: 'multiselect',
        disableToolbarSelect: disableToolbarSelect,
        customToolbarSelect: () =>
          <div style={{ justifyContent: 'none', paddingRight: 24 }}>
            <TagMenu tags={tags} onConfirm={handleTagsConfirm} />
            <SelectAllCheckbox />
          </div>,
        customToolbar: SelectAllCheckbox,
        selectableRowsHeader: false,
        page: page,
        onChangePage: setPage,
        rowsPerPage: PER_PAGE,
        onTableChange: handleOnFilterChange,
        ...options,
      }}
    />
  );
}

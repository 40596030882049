function parseExcel(formData) { 
  return fetch('/files/_parse', {
    method: 'POST',
    body: formData
  })
  .then(res => {
    if (res.status >= 400) {
      return Promise.reject(res.json());
    }

    return res.json()
  })
}

function upload(formData, namespace = 'default') {
  return fetch(`/files?namespace=${namespace}`, {
    method: 'POST',
    body: formData
  })
  .then(res => {
    if (res.status >= 400) {
      return Promise.reject(res.json());
    }

    return res.json()
  })
}

export { parseExcel, upload }

import React from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import useStyles from './styles';

import Select from '@components/core/Select';
import { Autocomplete } from '@material-ui/lab';

const initialValues = {
  text: '',
  tone: '友善',
  sentiment: '中立',
  genOnce: false,
};

function AIGenerate({
  handleGenerateReply,
  handleGenOnce,
  isLoading,
  control,
  getValues,
}) {
  const classes = useStyles();

  const toneOptions = [
    { name: '網民', value: '網民' },
    { name: '專業', value: '專業' },
    { name: '直率', value: '直率' },
    { name: '自信', value: '自信' },
    { name: '友善', value: '友善' },
  ];

  const sentimentOptions = [
    { name: '正向', value: '正向' },
    { name: '中立', value: '中立' },
    { name: '負面', value: '負面' },
  ];

  const handleSubmit = () => {
    handleGenerateReply(getValues());
  };

  return (
    <Paper
      elevation={0}
      style={{ border: '1px solid #ccc', padding: 16, marginBottom: 24 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <h3
            style={{
              margin: 0,
              position: 'absolute',
              top: -16,
              background: '#fff',
              padding: '0 8px',
            }}
          >
            人工智慧生成
          </h3>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={6}>
            <Controller
              name="tone"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    className={classes.formItem}
                    label="語氣"
                    options={toneOptions}
                    variant="outlined"
                    inputProps={field}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="sentiment"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    className={classes.formItem}
                    label="情緒"
                    options={sentimentOptions}
                    variant="outlined"
                    inputProps={field}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="keywords"
              control={control}
              render={({ field: props }) => {
                return (
                  <Autocomplete
                    {...props}
                    multiple
                    options={[]}
                    freeSolo
                    onChange={(e, data) => props.onChange(data)}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          key={option}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="關鍵字"
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="text"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    className={classes.formItem}
                    multiline
                    variant="outlined"
                    placeholder="欲回覆的內容"
                    inputProps={field}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="genOnce"
              control={control}
              render={({ field: props }) => {
                return (
                  <>
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        handleGenOnce(e.target.checked);
                      }}
                    />
                    產出文章是否全部相同
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Button
              variant="contained"
              color="primary"
              style={{ float: 'right' }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? '生成中...' : '人工智慧生成'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AIGenerate;

import { authPost } from './commons';

function genBody(doc) {
  if (doc.type === 'image' || doc.type === 'video') {
    return {
      type: doc.type,
      contents: [{
        type: doc.type,
        source: doc.file,
      }]
    };
  }
  if (doc.type === 'post' || doc.type === 'comment') {
    return {
      type: doc.type,
      contents: [{ type: 'text', text: doc.content }],
    };
  }
  if (doc.type === 'join_group_forms') {
    return {
      type: doc.type,
      contents: doc.forms.map(f => ({ type: 'text', text: f })),
    };
  }

  return {};
}

function add(user_id, payload) {
  const doc = {
    name: payload.name,
    ...genBody(payload),
  };

  return authPost(`/api/v1/users/${user_id}/materials`, doc);
}

export { add }; 

import create from 'zustand';

// steps: [
//   {
//     currentStep: 0,
//     data: null,
//   },
// ],

const useStepStore = create((set) => ({
  formData: null,
  excelData: null,
  setFormData: (formData) => {
    set((state) => ({
      ...state,
      formData,
    }));
  },
  setExcelData: (excelData) => {
    set((state) => ({
      ...state,
      excelData,
    }));
  },
  clearState: () =>
    set(
      (state) => ({
        ...state,
        formData: null,
        excelData: null,
      }),
      true,
    ),
}));

export default useStepStore;

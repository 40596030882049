import React, { useState } from 'react';
import {
  InputLabel,
  Chip,
  IconButton,
  Select,
  OutlinedInput,
  Box,
  MenuItem,
  Button,
} from '@material-ui/core';
import useTags from '@hooks/useTags';
import { Edit as EditIcon } from '@material-ui/icons';

export default function TagSetting({ onChange }) {
  const { tags } = useTags('task');
  const [selectedTags, setSelectedTags] = useState([]);
  const [editing, setEditing] = useState(false);

  return (
    <>
      <InputLabel shrink>標籤設置</InputLabel>
      {editing ? (
        <Select
          multiple
          value={selectedTags}
          onChange={(e) => setSelectedTags(e.target.value)}
          input={<OutlinedInput label="Chip" />}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const currentTag = tags.find((t) => t.id === value);
                  return (
                    <Chip
                      key={value}
                      label={currentTag?.name}
                      style={{
                        backgroundColor: currentTag?.color,
                        color: '#fff',
                      }}
                    />
                  );
                })}
              </Box>
            );
          }}
        >
          {tags.map((item) => (
            <MenuItem key={item.name} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <>
          {selectedTags?.map((value) => {
            const currentTag = tags.find((t) => t.id === value);
            return (
              <Chip
                key={value}
                label={currentTag?.name}
                style={{
                  backgroundColor: currentTag?.color,
                  color: '#fff',
                }}
              />
            );
          })}
        </>
      )}

      {editing ? (
        <Button
          fontSize="small"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            onChange(selectedTags);
            setEditing(!editing);
          }}
          style={{
            marginLeft: '0.5rem',
          }}
        >
          確認
        </Button>
      ) : (
        <IconButton color="inherit" onClick={() => setEditing(!editing)}>
          <EditIcon color="action" fontSize="small" />
        </IconButton>
      )}
    </>
  );
}

import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { Grid } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';
import DayJsUtils from '@date-io/dayjs';
import fetcher from '@/libs/fetcher';
import { useUserState } from '@/context/UserContext';
import useTags from '@hooks/useTags';
import DataTable from '@components/core/DataTable';
import Select from '@components/core/Select';
import AccountStatusChart from './components/Chart/AccountStatus';
import TasksChart from './components/Chart/Tasks';
import TasksByTagChart from './components/Chart/TasksByTag';
import TasksList from './components/TasksList';
import { TASK_STATUS } from '@/constants';

import 'dayjs/locale/en';
dayjs.locale('en');

export default function Dashboard(props) {
  const { user } = useUserState();
  const [startDate, setStartDate] = useState(dayjs().subtract(10, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedTags, setSelectedTags] = useState([]);
  const { tags } = useTags('task');
  const { data: tasks } = useSWR(`/users/${user.id}/tasks`, (url) =>
    fetcher({ url }),
  );
  const { data: dashboardData } = useSWR(
    {
      url: `/users/${user.id}/dashboard/_search`,
      startDate,
      endDate,
      selectedTags,
    },
    ({ url, startDate, endDate, selectedTags }) =>
      fetcher({
        url,
        method: 'POST',
        data: {
          tags: selectedTags,
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
        },
      }),
  );

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'created_at',
      label: '建立時間',
      options: {
        customBodyRenderLite: (idx) =>
          new Date(tasks.data?.[idx].created_at * 1000).toLocaleString(),
      },
    },
    {
      name: 'updated_at',
      label: '上次更新',
      options: {
        customBodyRenderLite: (idx) =>
          tasks.data?.[idx].updated_at &&
          new Date(tasks.data?.[idx].updated_at * 1000).toLocaleString(),
      },
    },
    {
      name: 'TASK_STATUS',
      label: '狀態',
      options: {
        customBodyRenderLite: (idx) =>
          TASK_STATUS[tasks.data?.[idx].TASK_STATUS],
      },
    },
  ];

  const { sharePost, likePost } = useMemo(() => {
    function filterByType(type) {
      return tasks?.data.filter((item) => item.type === type);
    }
    return {
      sharePost: filterByType('share.post'),
      likePost: filterByType('like.post'),
    };
  }, [tasks?.data]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Select
            style={{ width: 300 }}
            variant="outlined"
            label="任務標籤"
            name="tags"
            options={tags.map((tag) => ({
              name: tag.name,
              value: tag.id,
            }))}
            multiple
            value={selectedTags}
            onChange={({ target }) => setSelectedTags(target.value)}
          />
          <div style={{ float: 'right' }}>
            <MuiPickersUtilsProvider locale="en" utils={DayJsUtils}>
              <KeyboardDatePicker
                label="開始日期"
                format="YYYY-MM-DD"
                value={startDate}
                onChange={setStartDate}
              />
              <KeyboardDatePicker
                label="結束日期"
                format="YYYY-MM-DD"
                value={endDate}
                onChange={setEndDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <AccountStatusChart data={dashboardData?.data?.statistics} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TasksChart data={dashboardData?.data?.statistics} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TasksByTagChart data={dashboardData?.data?.statistics} tags={tags} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TasksList data={dashboardData?.data?.statistics} tags={tags} />
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <DataTable
            title="按讚列表"
            data={likePost}
            columns={columns}
            options={{
              filterArrayFullMatch: false,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DataTable
            title="分享列表"
            data={sharePost}
            columns={columns}
            options={{
              filterArrayFullMatch: false,
            }}
          />
        </Grid> */}
      </Grid>
    </>
  );
}

import { useState } from 'react';
import { Box, Stepper, Step, StepButton } from '@material-ui/core';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const steps = ['上傳檔案', '上傳清單設定', '檢舉人設定'];

export default function BatchStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    const newActiveStep = isLastStep()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setCompleted(newCompleted);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    const newCompleted = completed;
    newCompleted[activeStep - 1] = false;
    setCompleted(newCompleted);
    setActiveStep(activeStep - 1);
  };

  const stepsContent = {
    0: <Step1 handleNext={handleNext} />,
    1: <Step2 handleNext={handleNext} handleBack={handleBack} />,
    2: <Step3 handleBack={handleBack} />,
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" disabled>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>{stepsContent[activeStep]}</div>
    </Box>
  );
}

import useSWR from 'swr';
import fetcher from '@/libs/fetcher';
import { useUserState } from '@/context/UserContext';

export default function useMaterials(types=[]) {
  const { user } = useUserState();
  const { data, error, mutate } = useSWR(user?.id ? `/users/${user.id}/materials` : null, (url) =>
    fetcher({ url, needToken: true })
  )

  const materials = (data?.data || [])
    .filter(row => types.length === 0 ? true : types.includes(row.type))

  return {
    materials: materials,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

import React from 'react';
import {
  Breadcrumbs,
  Typography,
  CircularProgress,
  Paper,
  Table,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import useAPI from '@/services/api';
import PageTitle from '@components/core/PageTitle';
import AccountTasks from '@components/shared/AccountTasks';
import FacebookInfo from './components/FacebookInfo';
import YoutubeInfo from './components/YoutubeInfo';
import FacebookGroups from './components/FacebookGroups';
import FacebookFriends from './components/FacebookFriends';
import useStyles from './styles';

export default function AccountDetail(props) {
  const classes = useStyles();
  const { id } = useParams();

  const { isFetching, data } = useAPI(`/accounts/${id}`);
  const account = data[0];

  if (isFetching) {
    return <CircularProgress />;
  }

  return (
    <>
      <PageTitle
        title={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/app/accounts">
              帳號管理
            </Link>
            <Typography color="textPrimary">{account.name}</Typography>
          </Breadcrumbs>
        }
      />
      { account?.platform === 'facebook' && <FacebookInfo account={account} profile={account.profile} /> }
      { account?.platform === 'youtube' && <YoutubeInfo account={account} profile={account.profile} /> }
      <Paper elevation={0} className={classes.taskInfo}>
        <Table>
          <AccountTasks id={account.id} />
        </Table>
      </Paper>
      {
        account?.platform === 'facebook' && (
        <>
          <Paper elevation={0} className={classes.taskInfo}>
            <FacebookGroups groups={account?.groups || []} />
          </Paper>
          <Paper elevation={0} className={classes.taskInfo}>
            <FacebookFriends friends={account?.friends || []} />
          </Paper>
        </>
      )}
    </>
  );
}

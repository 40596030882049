import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  TextField,
  Paper,
  Divider,
  Chip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Select from '@components/core/Select';
import { useForm, Controller } from 'react-hook-form';

import useTags from '@hooks/useTags';
import useStyles from './styles';
import { PLATFORMS, TRIGGER_CONDITIONS, ACTION_TYPE, YT_ACTION_TYPE } from './constants';

const initialValues = {
  name: '',
  platform: 'facebook',
  action: '',
  trigger: 'once',
  is_private_group: false,
  tags: [],
};

const Step1 = ({ handleNext, onNext }) => {
  const { tags } = useTags('task');
  const [done, setDone] = useState(false);
  const classes = useStyles();

  const {
    control,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  });

  const name = watch('name')
  const action = watch('action')
  const platform = watch('platform')
  useEffect(() => {
    if (name === '' || action === '') {
      return setDone(false)
    }

    setDone(true)
  }, [name, action])

  const handleOnNext = () => {
    const doc = getValues();
    doc.tags = doc.tags.map((tag) => tag.value);
    onNext(doc);
    handleNext();
  }

  const compareColor = (color) => {
    let r,g ,b;
    r = parseInt(color.substring(1,3),16);
    g = parseInt(color.substring(3,5),16);
    b = parseInt(color.substring(5,7),16);

    return (r*0.299 + g*0.587 + b*0.114) > 186 ? 'black' : '#fff';
  }

  return (
    <>
      <form>
        <Paper elevation={0} className={classes.layoutContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      className={classes.formItem}
                      variant="outlined"
                      label="名稱*"
                      inputProps={field}
                    />
                  );
                }}
              ></Controller>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="trigger"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      className={classes.formItem}
                      variant="outlined"
                      label="觸發條件*"
                      value={value}
                      onChange={onChange}
                      options={TRIGGER_CONDITIONS}
                    />
                  )
                }}
              ></Controller>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="platform"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      className={classes.formItem}
                      variant="outlined"
                      label="平台"
                      value={value}
                      onChange={onChange}
                      options={PLATFORMS}
                    />
                  )
                }}
              ></Controller>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="action"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      className={classes.formItem}
                      variant="outlined"
                      label="動作*"
                      name="action"
                      value={value}
                      onChange={onChange}
                      options={platform === 'facebook' ? ACTION_TYPE : YT_ACTION_TYPE}
                    />
                  );
                }}
              ></Controller>
            </Grid>
            {
              action === 'join.group' &&
              <Grid item xs={12} lg={6}>
                <Controller
                  name="is_private_group"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        className={classes.formItem}
                        variant="outlined"
                        label="是否為私密社團"
                        inputProps={field}
                        options={[{ name: '是', value: true }, { name: '否', value: false }]}
                      />
                    );
                  }}
                ></Controller>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
            <Divider />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="tags"
                control={control}
                render={({ field: props }) => {
                  return (
                    <Autocomplete
                      {...props}
                      multiple
                      options={tags.map((tag) => ({
                        name: tag.name,
                        value: tag.id,
                        color: tag.color,
                      }))}
                      // value={props.value}
                      onChange={(e, data) => props.onChange(data)}
                      getOptionSelected={(option, value) => {
                        return option.value === value.value;
                      }}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            style={{
                              backgroundColor: option.color,
                              fontSize: 12,
                              color: compareColor(option.color),
                            }}
                            variant="outlined"
                            label={option.name}
                            {...getTagProps({ index })}
                            deleteIcon={<HighlightOffIcon style={{color: compareColor(option.color)}} />}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="任務標籤"
                        />
                      )}
                    />
                  );
                }}
              ></Controller>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                label="備註"
                name="description"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnNext}
          sx={{ mr: 1 }}
          disabled={!done}
        >
          下一步
        </Button>
      </Box>
    </>
  );
};

export default Step1;

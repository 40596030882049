import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  basicInfo: {
    padding: theme.spacing(3),
  },
  platformInfo: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  taskInfo: {
    marginTop: theme.spacing(3),
  },
  formItem: {
    width: '100%',
  },
  chipList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  tagChip: {
    marginRight: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
}));

import React, { useState } from 'react';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import fetcher from '@/libs/fetcher';
import {
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { Grid, Button, IconButton } from '@material-ui/core';
import { useUserState } from '@/context/UserContext';
import DataTable from '@components/core/DataTable';
import PageTitle from '@components/core/PageTitle';
import Info from '@components/core/Info';
import popup from '@components/core/Popup';
import { MATERIAL_NAME } from './constants';

function useMaterials(id) {
  const { data, error, mutate } = useSWR(id ? `/users/${id}/materials` : null, (url) =>
    fetcher({ url, needToken: true })
  )

  function remove(mid) {
    fetcher({
      url: `/users/${id}/materials/${mid}`,
      method: 'DELETE',
      needToken: true
    })
    .then(_ => mutate())
    .catch(_ => {
      popup({
        title: '錯誤',
        content: '無法刪除此素材，使用者僅能刪除自己的創建的素材'
      })
    })
  }

  return {
    materials: data?.data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
    remove,
  }
}

export default function Materials() {
  const { user } = useUserState();
  const history = useHistory();
  const { materials, remove } = useMaterials(user?.id);
  const [notify, setNotify] = useState({ open: false, message: '' });

  const columns = [
    {
      name: 'index',
      label: '項次',
      options: {
        filter: false,
        download: false,
        customBodyRenderLite: (idx) => <>{idx + 1}</>,
      },
    },
    {
      name: 'id',
      label: '項次',
      options: { display: false, filter: false, download: false },
    },
    {
      name: 'name',
      label: '名稱',
      options: { filter: false },
    },
    {
      name: 'type',
      label: '類型',
      options: {
        customBodyRender: (type) => MATERIAL_NAME[type] || type,
      }
    },
    {
      name: 'created_at',
      label: '創建時間',
      options: {
        filter: false,
        customBodyRender: (created_at) => dayjs.unix(created_at).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      name: 'preview',
      label: '預覽',
      options: {
        filter: false,
        search: false,
        customBodyRenderLite: (idx) => {
          if (materials[idx]?.type !== 'image') {
            return null
          }

          const filename = materials[idx].contents.pop()?.source?.filename
          const imageUrl = `/files/${filename}`

          return <img alt="預覽" width={150} src={imageUrl} />
        }
      }
    },
    {
      name: 'id',
      label: '操作',
      options: {
        filter: false,
        customBodyRender: (id) => 
          <>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            aria-controls="profile-menu"
            onClick={() => remove(id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          </>
      }
    },
  ];

  return (
    <>
      <PageTitle
        title="素材庫"
        extra={
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push('/app/materials/create')}
          >
            新增素材
          </Button>
        }
      />
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            data={materials}
            columns={columns}
            options={{
              elevation: 0,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

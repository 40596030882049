import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Button,
  Grid,
  TextField,
  Checkbox,
  Divider,
  Badge,
  IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';

import Select from '@components/core/Select';
import MultiTextField from '@components/shared/MultiTextField';
import popup from '@components/shared/Popup';

import { upload } from '@/services/files';
import useMaterials from '@/hooks/useMaterials';
import useStyles from './styles';
import { TARGET_TYPE, REACT_TYPE } from './constants';
import { ACTION_NAME } from '@constants';

const initialValues = {
  target_type: '',
  target_url: '',
  react_type: 'Like',
  content: '',
  report_reason: '',
  attachment_type: 'image',
  material: null,
  answer_list: [''],
  datetime: dayjs().format('YYYY-MM-DD HH:mm'),
};
  
const Step3 = ({
  handleBack,
  flow,
  selectedAccs,
  onOk,
  reply,
  editables,
  handleTglEditables,
}) => {
  const [datas, setDatas] = useState(
    new Array(selectedAccs.length).fill({ ...initialValues }),
  );

  const handleDataChange = (idx) => (field, value) => {
    datas[idx] = { ...datas[idx], [field]: value };
    setDatas([...datas]);
  };

  const handleOnNext = () => {
    onOk({ editables, datas });
  };

  useEffect(() => {
    if (reply.length === 1) {
      handleDataChange(0)('content', reply[0]);
    }
    if (reply.length > 1) {
      for (let i = 0; i < editables.length; i++) {
        if (editables[i]) {
          handleDataChange(i)('content', reply[i]);
        }
      }
    }
  }, [reply]);

  return (
    <>
      <Box
        sx={{
          pt: 2,
          border: '1px solid #ccc',
          p: 2,
          borderRadius: 4,
          position: 'relative',
        }}
      >
        <h3
          style={{
            margin: 0,
            position: 'absolute',
            top: -12,
            background: '#fff',
            padding: '0 8px',
          }}
        >
          {ACTION_NAME[flow.action]}
        </h3>
        {selectedAccs.map((acc, i) => (
          <ContentSetting
            key={acc}
            idx={i}
            editable={editables[i]}
            flow={flow}
            account={acc}
            onEditChange={handleTglEditables(i)}
            data={datas[i]}
            onDataChange={handleDataChange(i)}
            groups={acc.groups}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          style={{ marginRight: 12 }}
        >
          上一步
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnNext}
          sx={{ mr: 1 }}
        >
          確定
        </Button>
      </Box>
    </>
  );
};

function ContentSetting({ flow, account, idx, editable, onEditChange, data, onDataChange, groups = [] }) {
  const classes = useStyles();

  const action = flow.action;
  const is_private_group = flow.is_private_group;
  const trigger = flow.trigger;

  const { materials } = useMaterials(['image', 'video', 'join_group_forms']);
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [selectedFormMaterial, setSelectedFormMaterial] = useState('');
  const [file, setFile] = useState(null);

  const handleTglEditable = ({ target }) => {
    onEditChange(target.checked)
  }

  const handleValueChange = (field) => ({ target }) => {
    onDataChange(field, target.value)
  }

  const handleDatetimeChange = (m) => {
    onDataChange('datetime', m.format('YYYY-MM-DD HH:mm:ss'))
  }

  const handleAnsChange = (texts) => {
    onDataChange('answer_list', texts)
  }

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file)

    upload(formData)
      .then(data => {
        setFile(data.payload)
        setSelectedMaterial('')
        popup({
          title: '檔案上傳成功'
        });
      })
  }

  const handleSelectMaterial = ({ target }) => {
    setFile(null)
    setSelectedMaterial(target.value)
  }

  const handleRemoveMaterial = () => {
    setFile(null)
    setSelectedMaterial('')
  }

  const handleSelectFormMaterial = ({ target }) => {
    setSelectedFormMaterial(target.value)
  }

  useEffect(() => {
    if (file) {
      return onDataChange('material', { url: `/files/${file.filename}`, path: file.path })
    }

    if (selectedMaterial !== '') {
      const m = materials.find(m => m.id === selectedMaterial)
      const filename = m?.contents[0]?.source?.filename
      const path = m?.contents[0]?.source?.path

      onDataChange('material', { url: `/files/${filename}`, path: path })
    }

    if (!file && selectedMaterial === '') {
      onDataChange('material', null)
    }

  }, [selectedMaterial, file])

  useEffect(() => {
    if (selectedFormMaterial !== '') {
      const m = materials.find(m => m.id === selectedFormMaterial)

      onDataChange('answer_list', m.contents.map(c => c.text))
    }

  }, [selectedFormMaterial])

  return (
    <Paper  elevation={0} style={{marginTop: 12}}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <h2 style={{margin: '0', fontSize: 28, fontWeight: '400'}}>{ account.name }</h2>
          {
            idx > 0 && <div style={{display: 'flex', alignItems: 'center'}}><Checkbox checked={!editable} onChange={handleTglEditable} />與第一則相同</div>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} style={{ paddingTop: 12 }}>
          {
            trigger === 'schedule' && editable &&
            <Grid item xs={11}>
              <MuiPickersUtilsProvider locale="en" utils={DayJsUtils}>
                <DateTimePicker
                  label="開始日期"
                  format="YYYY-MM-DD HH:mm"
                  className={classes.formItem}
                  value={data.datetime}
                  onChange={handleDatetimeChange}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          {
            ['create.post', 'comment.post', 'reply.post', 'share.post'].includes(action) && editable &&
            <Grid item xs={12}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                label="內容"
                name="content"
                value={data.content}
                onChange={handleValueChange("content")}
                multiline
                rows={4}
              />
            </Grid>
          }
          {
            ['share.post'].includes(action) && editable &&
            <Grid item xs={11}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="頁面種類"
                name="target_type"
                value={data.target_type}
                options={TARGET_TYPE}
                onChange={handleValueChange("target_type")}
              />
            </Grid>
          }
          {
            ['share.post'].includes(action) && data.target_type !== 'self' && editable &&
            <Grid item xs={11}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                name="target_url"
                label="分享至網址"
                value={data.target_url}
                onChange={handleValueChange("target_url")}
              />
            </Grid>
          }
          {
            action === 'like.post' && editable &&
            <Grid item xs={11}>
              <Select
                className={classes.formItem}
                variant="outlined"
                label="表情"
                name="react_type"
                options={REACT_TYPE}
                value={data.react_type}
                onChange={handleValueChange("react_type")}
              />
            </Grid>
          }
          {
            action === 'report' && editable &&
            <Grid item xs={12} lg={6}>
              <TextField
                className={classes.formItem}
                variant="outlined"
                label="檢舉原因"
                value={data.report_reason}
                onChange={handleValueChange("report_reason")}
              />
            </Grid>
          }
          {
            action === 'join.group' && editable && is_private_group &&
            <>
              <Grid item xs={11}>
                <Select
                  className={classes.formItem}
                  variant="outlined"
                  label="直接套用素材庫"
                  options={
                    materials
                      .filter(m => m.type === 'join_group_forms')
                      .map(m => ({ name: m.name, value: m.id }))
                  }
                  value={selectedFormMaterial}
                  onChange={handleSelectFormMaterial}
                />
              </Grid>
              <Grid item xs={11}>
                <Divider />
              </Grid>
              <Grid item xs={11}>
                <MultiTextField texts={data.answer_list} onTextsChange={handleAnsChange} />
              </Grid>
            </>
          }
          {['create.post', 'comment.post'].includes(action) && editable && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Select
                    className={classes.formItem}
                    variant="outlined"
                    label="附件種類"
                    name="attachment_type"
                    options={[{ name: '圖片', value: 'image' }, { name: '影音', value: 'video' }]}
                    value={data.attachment_type}
                    onChange={handleValueChange("attachment_type")}
                  />
                </Grid>
                <Grid item xs={6} lg={2} style={{display: 'flex', alignItems: 'center'}}>
                  <Button
                    variant="contained"
                    component="label"
                  >
                    上傳檔案
                    <input
                      type="file"
                      hidden
                      onChange={e => handleUpload(e.target.files[0])}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Select
                    className={classes.formItem}
                    variant="outlined"
                    label="從素材庫中選取"
                    options={
                      materials
                        .filter(m => m.type === data.attachment_type)
                        .map(m => ({ name: m.name, value: m.id }))
                    }
                    value={selectedMaterial}
                    onChange={handleSelectMaterial}
                  />
                </Grid>
                {
                  data?.material?.url &&
                  <Grid item xs={6} lg={4}>
                    <Badge badgeContent={<IconButton onClick={handleRemoveMaterial}><DeleteIcon /></IconButton>}>
                      <img width="100%" alt="預覽" src={data?.material?.url} />
                    </Badge>
                  </Grid>
                }
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Step3;

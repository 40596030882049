import React from 'react';
import { Grid } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableRow, TableHead, TableCell } from '@material-ui/core';

import { PROFILE_FIELDS } from '@constants'

export default function FlowDetail({ flow, content }) {
  const actionType = flow?.action?.type || flow?.actions[0]?.type

  if (actionType === 'update.profile') {
    return <ProfileTask action={flow.action} />
  }
  if (actionType === 'batch.report') {
    return <BatchReportTask action={flow.action} />
  }
  if (actionType === 'edit.privacy') {
    return <PrivacyTask action={flow.action} />
  }
  if (actionType === 'add.friend') {
    return <AddFriendTask action={flow.action} />
  }

  const url = flow?.action?.post_url || flow?.action?.url;

  return (
    <Grid container spacing={4}>
      <Grid item xs={2}>
        網址:
      </Grid>
      <Grid item xs={10}>
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      </Grid>
      <Grid item xs={2}>
        內容:
      </Grid>
      <Grid item xs={10}>
        {content}
      </Grid>
    </Grid>
  );
}

function ProfileTask({ action }) {
  return (
    <TableContainer style={{ minWidth: 300 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>欄位</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          action.target_types.map(type => (
            <TableRow>
              <TableCell>
                { PROFILE_FIELDS[type] || type }
              </TableCell>
              <TableCell>
                { action[type] }
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function PrivacyTask({ action }) {
  const targetMap = {
    future_posts: '誰可以觀看未來發文',
    profile_pic: '誰可以看到過去大頭貼'
  }
  const privacyMap = {
    public: '公開',
    friends: '只限朋友',
    only_me: '只限自己',
  }

  return (
    <TableContainer style={{ minWidth: 300 }}>
      <Table>
        <TableBody>
         <TableRow>
           <TableCell>
             { targetMap[action?.target] || action?.target }
           </TableCell>
           <TableCell>
             { privacyMap[action?.privacy_type] || action?.privacy_type}
           </TableCell>
         </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function BatchReportTask({ action }) {
  return (
    <Grid container spacing={4}>
    {
      action.entities.map(row => (
        <>
          <Grid item xs={2}>
            網址: 
          </Grid>
          <Grid item xs={10}>
            { row.url }
          </Grid>
        </>
      ))
    }
    </Grid>
  );
}

function AddFriendTask({ action }) {
  return (
    <Grid container spacing={4} style={{ width: 400 }}>
      <Grid item xs={4}>
        好友ID: 
      </Grid>
      <Grid item xs={7}>
        { action.profile_id }
      </Grid>
    </Grid>
  );
}

import { authPost } from './commons';

function genReply(formData) {
  const doc = {
    ...formData
  }

  return authPost('/api/v1/jobs/reply', doc);
}

function genContent({ text, n }) {
  const doc = { text, n }

  return authPost('/api/v1/jobs/content', doc);
}

export { genReply, genContent }

import React from "react";
import DataTable from '@components/core/DataTable';
import { ACTION_NAME } from '@constants';

export default function TasksList({ data, tags }) {
  const tableData = data?.tasks_by_tag_type_url.map(row => ({
    ...row,
    tag: tags.find(t => t.id === row.tag)?.name,
    type: ACTION_NAME[row.type] || row.type,
  }))
  .sort((a, b) => b.count - a.count)
  const columns = [
    {
      name: 'index',
      label: '名次',
      options: {
        filter: false,
        customBodyRenderLite: (idx) => <>{idx + 1}</>,
      },
    },
    {
      name: 'tag',
      label: '標籤',
    },
    {
      name: 'type',
      label: '動作',
    },
    {
      name: 'url',
      label: '網址',
      options: {
        filter: false,
        customBodyRender: (url) => url?.length >= 75
          ? <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#4a4a4a' }}>{ url.slice(0, 75) + '...' }</a>
          : <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#4a4a4a' }}>{ url }</a>
      },
    },
    {
      name: 'count',
      label: '次數',
      options: {
        filter: false,
      },
    }
  ]

  return (
    <DataTable
      title="任務列表"
      data={tableData}
      columns={columns}
      options={{
        filterArrayFullMatch: false,
        download: true,
        downloadOptions : {
          filename: 'tasks.csv',
        }
      }}
    />
  );
}

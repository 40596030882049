import React from "react";

import login from '../services/login';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, user: action.user };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const payload = parseToken(localStorage.getItem("id_token"))
  const isAuthenticated = payload && payload.exp && (Date.now() / 1000) < payload.exp

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: isAuthenticated,
    user: payload,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, email, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  login(email, password)
  .then(data => {
    if (data.success) {
      localStorage.setItem('id_token', data.token)
      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS', user: data.payload })
      history.push('/')
    } else {
      dispatch({ type: "LOGIN_FAILURE" });
      setError(true);
      setIsLoading(false);
    }
  })
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

function parseToken(token) {
  try {
    const payload = token.split(".")[1];

    return JSON.parse(atob(payload));
  } catch (e) {
    return {}
  }
}

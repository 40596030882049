import React, { useState } from 'react';
import { Grid, Chip, CircularProgress } from '@material-ui/core';
import dayjs from 'dayjs';
import useAPI from '@/services/api';
import { useUserState } from '@/context/UserContext';
import Info from '@components/core/Info';
import Calendar from '@components/core/Calendar';

const EventCard = ({event}) => {
    return (
      <Chip
        label={event.title}
        size='small'
        color={event.status === 'DONE' ? 'primary' : 'secondary'}
        style={{ cursor: 'pointer' }}
        variant={event.status === 'scheduling' ? 'outlined': 'default'}
      />
    );
}

const overlay = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.7)',
  zIndex: 1,
}

export default function Flows() {
  const { user } = useUserState();
  const [startDate, setStartDate] = useState(dayjs().subtract(10, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [notify, setNotify] = useState({ open: false, message: '' });

  const { data: tasks, isFetching } = useAPI(
    `/users/${user.id}/tasks?start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}&include_scheduled=true`
  );

  const events = tasks.map(task => ({
    id: task.id,
    title: task.account_name,
    start: new Date(task.updated_at * 1000),
    end: new Date(task.updated_at * 1000 + 3600000),
    status: task.status,
  }))

  const handleRangeChange = (r) => {
    setStartDate(dayjs(r.start));
    setEndDate(dayjs(r.end));
  }

  return (
    <>
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          { isFetching && <div style={overlay}><CircularProgress color="inherit" /></div> }
          <Calendar
            onRangeChange={handleRangeChange}
            events={events}
            EventComponent={EventCard}
          />
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Paper, Box, Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import AccountList from '@components/shared/AccountList';
import YTAccountSetting from './YTAccountSetting';
import AIGenerateContent from './AIGenerateContent';
import { genContent } from '@/services/jobs';

const initialValues = {
  text: '',
  genOnce: false,
};

function genTrigger(doc) {
  return {
    type: 'schedule',
    schedule: {
      datetime: doc.datetime,
    },
  };
}

function Setting({
  flow,
  idx,
  setting,
  onSettingChange,
  onSettingDeleted,
  onStatusChange = () => {},
}) {
  const [settingStatus, setSettingStatus] = useState('');
  const [reply, setReply] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editables, setEditables] = useState([]);

  const {control, getValues, setValue} = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  })

  const handleTglEditables = (idx) => (checked) => {
    editables[idx] = !checked
    setEditables([ ...editables ])

    if (!checked) {
      let n = 0;
      editables.forEach((e) => e === true && n++);
      if (n > 1) {
        setValue('genOnce', false);
      }
    }
  }

  useEffect(() => {
    onStatusChange(settingStatus);
  }, [settingStatus, onStatusChange]);

  const handleTglSetting = () => {
    if (settingStatus === '') {
      return setSettingStatus('account');
    }

    setSettingStatus('');
  };
  const handleDeleteSetting = () => {
    onSettingDeleted();
    onStatusChange('');
  };
  const handleSelectAccs = (accs) => {
    onSettingChange({ ...setting, accounts: accs });
    setEditables(new Array(accs.length).fill(true));
  };
  const handleGenerateContent = (formData) => {
    let n = 0;
    editables.forEach((e) => e === true && n++);
    if (formData.genOnce) n = 1;

    const doc = {
      ...formData,
      n,
    };

    setIsLoading(true);
    genContent(doc)
      .then((res) => {
        setReply(res.payload.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });

    setReply([]);
  };
  const handleOK = ({ datas, editables }) => {
    const first = datas[0];
    const actions = datas.map((doc, i) => {
      let ret = {};

      //same as the first one
      if (!editables[i]) {
        ret = {
          ...first,
          ...flow,
          post_url: first.post_url || setting.post_url,
          channel_id: setting.channel_id || '',
          attachment: first?.material?.path,
        };
        if (flow.trigger === 'schedule') {
          ret['trigger'] = genTrigger(first);
        }
      } else {
        ret = {
          ...doc,
          ...flow,
          post_url: doc.post_url || setting.post_url,
          channel_id: setting.channel_id || '',
          attachment: doc?.material?.path,
        };
        if (flow.trigger === 'schedule') {
          ret['trigger'] = genTrigger(doc);
        }
      }

      return ret;
    });

    onSettingChange({ ...setting, actions });
    setSettingStatus('');
  };

  const handleGenOnce = (checked) => {
    if (checked) setEditables(new Array(setting.accounts.length).fill(false).map((_, i) => i === 0));
    else setEditables(new Array(setting.accounts.length).fill(true));
  }

  const url = setting.post_url
    ? setting.post_url
    : `https://www.youtube.com/channel/${setting.channel_id}`;

  const accoutsFilter = (acc) => {
    if (acc.status !== 'active') {
      return false;
    }
    if (acc.platform !== 'youtube') {
      return false;
    }

    return true;
  };

  return (
    <Paper style={{ marginTop: 6, padding: '24px 24px 8px' }} elevation={0}>
      <Grid container>
        <Grid
          item
          spacing={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid #ccc',
            marginBottom: 24,
            padding: '0 16px',
            borderRadius: 4,
            position: 'relative',
          }}
          xs={12}
        >
          <h3 style={{position: 'absolute', top: -28, padding: '0 12px', background: '#fff'}}>網址</h3>
          <Grid item xs={1}>
            <h3>{`${idx}.`}</h3>
          </Grid>
          <Grid item xs={settingStatus === '' ? 7 : 9}>
            <h3 style={{ wordWrap: 'break-word' }}>{url}</h3>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <h3>{`已指派 ${(setting.accounts || []).length} 人`}</h3>
          </Grid>
          {settingStatus === '' && (
            <Grid item xs={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  pt: 1,
                }}
              >
                <Button onClick={handleTglSetting}>設定</Button>
                <Button onClick={handleDeleteSetting}>刪除</Button>
              </Box>
            </Grid>
          )}
        </Grid>
        {settingStatus === 'account' && (
          <Grid item xs={12}>
            <AccountList
              title="帳號列表"
              expandableRows={false}
              selectable={true}
              onSelect={(_, accs) => handleSelectAccs(accs)}
              filter={accoutsFilter}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSettingStatus('detail')}
              >
                下一步
              </Button>
            </Box>
          </Grid>
        )}
        {settingStatus === 'detail' && (
          <>
            {['yt.create.video', 'yt.comment.video'].includes(flow.action) && (
              <Grid item xs={12}>
                <AIGenerateContent
                  isLoading={isLoading}
                  handleGenerateReply={handleGenerateContent}
                  handleGenOnce={handleGenOnce}
                  control={control}
                  getValues={getValues}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <YTAccountSetting
                handleBack={() => setSettingStatus('account')}
                flow={flow}
                selectedAccs={setting?.accounts || []}
                onOk={handleOK}
                reply={reply}
                editables={editables}
                handleTglEditables={handleTglEditables}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default Setting;

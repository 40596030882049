import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
} from '@material-ui/core';

import Select from '@components/core/Select';

const initialValues = {
  name: '',
  platform: 'facebook',
  type: '',
  url: '',
}
export default function Modal({ onSubmit, handleClose }) {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  });

  const s = (data) => {
    onSubmit(data)
    handleClose()
  }

  return (
    <>
      <form onSubmit={handleSubmit(s)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{ required: '必填' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="名稱"
                  variant="outlined"
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="platform"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  variant="outlined"
                  label="平台"
                  options={[{ name: '臉書', value: 'facebook' }]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              rules={{ required: '必填' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  variant="outlined"
                  label="種類"
                  options={[
                    { name: '粉絲團', value: 'PAGE' },
                    { name: '社團', value: 'GROUP' },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="url"
              control={control}
              rules={{ required: '必填' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="網址"
                  variant="outlined"
                  inputProps={field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isValid}
            >
              送出
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

import useSWR from 'swr';
import fetcher from '@/libs/fetcher';
import { useUserState } from '@/context/UserContext';

const TAG_TYPE = {
  task: 0,
  account: 1,
  friend: 2,
};

export default function useTags(types) {
  const { user } = useUserState();
  const { data: tags, mutate } = useSWR(`/users/${user.id}/tags`, (url) =>
    fetcher({ url }),
  );
  const tagTypes = Array.isArray(types)
    ? types.map((type) => TAG_TYPE[type])
    : [types].map((type) => TAG_TYPE[type]);

  function updateSort(from, to) {
    return fetcher({
      url: `/users/${user.id}/tags/_sort`,
      method: 'POST',
      data: {
        from,
        to,
      },
    })
    .then(_ => mutate())
  }

  return {
    tags: tags?.data?.filter((tag) => tagTypes.includes(tag.type)) || [],
    updateSort,
  };
}

import React, { useState, useMemo } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function SearchSelect({
  label,
  name,
  value,
  onChange,
  options = [],
  inputProps,
  fullWidth = true,
  ...props
}) {

  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const displayedOptions = useMemo(
    () => options.filter(option => containsText(option.name, searchText)),
    [searchText, options]
  );

  return (
    <FormControl fullWidth {...props}>
      <InputLabel id="search-select-label">{ label }</InputLabel>
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        value={value}
        label={label}
        open={open}
        onOpen={() => setOpen(!open)}
        onChange={onChange}
        onClose={
          () => {
            setSearchText("")
            onChange({ target: { value: searchText }})
            setOpen(false)
          }
        }
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        renderValue={() => value}
      >
        {/* TextField is put into ListSubheader so that it doesn't
            act as a selectable item in the menu
            i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onChange(e)
                setOpen(false)
              }
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import {
  Dashboard as HomeIcon,
  AccountBox as AccountBoxIcon,
  List as TaskIcon,
  CalendarToday as CalendarIcon,
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  PhotoLibrary as PhotoLibraryIcon,
  LocalOffer as LocalOfferIcon,
  Report as ReportIcon,
  LibraryBooks as LibraryBooksIcon,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import SidebarLink from './components/SidebarLink';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '@/context/LayoutContext';

const structure = [
  {
    id: 0,
    label: 'Dashboard',
    link: '/app/dashboard',
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: '帳號管理',
    link: '/app/accounts',
    icon: <AccountBoxIcon />,
  },
  { id: 2, type: 'divider' },
  { id: 6, type: 'title', label: '流程' },
  {
    id: 3,
    label: '流程列表',
    link: '/app/flows',
    icon: <TaskIcon />,
  },
  {
    id: 12,
    label: '流程行事曆',
    link: '/app/calendar',
    icon: <CalendarIcon />,
  },
  {
    id: 4,
    label: '流程創建',
    link: '/app/flow-form',
    icon: <AddIcon />,
  },
  { id: 7, type: 'title', label: '進階' },
  {
    id: 8,
    label: '檢舉帳號狀態',
    link: '/app/batch-accounts',
    icon: <ReportIcon />,
  },
  {
    id: 9,
    label: '標籤管理',
    link: '/app/tags',
    icon: <LocalOfferIcon />,
  },
  {
    id: 10,
    label: '素材庫',
    link: '/app/materials',
    icon: <PhotoLibraryIcon />,
  },
  {
    id: 11,
    label: '目標清單',
    link: '/app/targets',
    icon: <LibraryBooksIcon />,
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);

import React, { useState, useMemo, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Paper, TextField, Button, Divider, Chip } from '@material-ui/core';
import dayjs from 'dayjs';
import useSWR from 'swr';

import Select from '@components/core/Select';
import SearchSelect from '@components/core/SearchSelect';
import Info from '@components/core/Info';
import Avatar from '@components/core/Avatar';
import { useUserState } from '@/context/UserContext';
import fetcher from '@/libs/fetcher';
import { upload } from '@/services/files';

import useStyles from '../styles';
import { RELATIONSHIP_OPTIONS, PRIVACY_OPTIONS } from './constants';

const useConfigs = () => {
  const { data } = useSWR(`/platforms/facebook/configs`, (url) =>
    fetcher({ url }),
  );

  return {
    departments: data?.data?.departments || [],
    places: data?.data?.places || [],
  }
}

const states = {
  active: 'success',
  pending: 'warning',
  died: 'secondary',
};
const PHOTO_NAMESPACE = 'fake_profile_photos/used';

export default function FacebookInfo({ account, profile = {} }) {
  const classes = useStyles();
  const { user } = useUserState();
  const { departments, places } = useConfigs();
  const [notify, setNotify] = useState({ open: false, message: '' });
  const [modifiedFields, setModifiedFields] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState(profile?.college);
  const [uploadedFile, setUploadedFile] = useState(null);

  const initialValues = {
    last_name: profile?.last_name || '',
    first_name: profile?.first_name || '',
    gender: profile?.gender,
    relationship: profile?.relationship,
    hometown: profile?.hometown,
    current_city: profile?.current_city,
    college: profile?.college,
    department: profile?.department,
    birthday: dayjs(profile?.birthday).format('YYYY-MM-DD'),
  }

  const {
    handleSubmit,
    control,
    reset,
    //formState: { errors, isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (modifiedFields.length === 0) {
      reset();
      setUploadedFile(null);
    }
  }, [modifiedFields, reset])

  const handleCancel = () => {
    setModifiedFields([])
    setSelectedCollege(profile?.college)
    setUploadedFile(null)
  }

  const handleFileUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file)

    upload(formData, PHOTO_NAMESPACE)
      .then(data => {
        setUploadedFile(data.payload)
        setModifiedFields([...modifiedFields, 'profile_photo'])
      })
  }

  const onSubmit = () => {
    const data = getValues()
    const payload = {
      name: `${account?.name} 更改檔案資料`,
      actions: [{
        type: 'update.profile',
        target_types: [...new Set(modifiedFields)],
        ...data,
        birthday: dayjs(data.birthday).format('MMM DD, YYYY'),
        profile_photo: uploadedFile?.path,
      }],
      trigger: {
        type: 'now',
      },
      accounts: [account?.id]
    };

    fetcher({
      url: `/users/${user?.id}/flows`,
      method: 'POST',
      data: payload,
    })
    .then(res => {
      if (res?.data?.success) {
        setNotify({ open: true, message: '送出成功！任務成功後請重新整理' });
        handleCancel();
      }
    })
    .catch((e) => setNotify({ open: true, message: '送出修改失敗' }));
  }

  const handleChangeFuturePostsPrivacy = ({ target }) => {
    const payload = {
      name: `${account?.name} 更改未來發文觀看權限`,
      actions: [{
        type: 'edit.privacy',
        privacy_type: target.value,
        target: 'future_posts',
      }],
      trigger: {
        type: 'now',
      },
      accounts: [account?.id]
    };

    fetcher({
      url: `/users/${user?.id}/flows`,
      method: 'POST',
      data: payload,
    })
    .then(res => {
      if (res?.data?.success) {
        setNotify({ open: true, message: '送出成功！任務成功後請重新整理' });
        handleCancel();
      }
    })
    .catch((e) => setNotify({ open: true, message: '送出修改失敗' }));
  }

  const handleChangeProfilePicPrivacy = ({ target }) => {
    const payload = {
      name: `${account?.name} 更改大頭貼權限`,
      actions: [{
        type: 'edit.privacy',
        privacy_type: target.value,
        target: 'profile_pic',
      }],
      trigger: {
        type: 'now',
      },
      accounts: [account?.id]
    };

    fetcher({
      url: `/users/${user?.id}/flows`,
      method: 'POST',
      data: payload,
    })
    .then(res => {
      if (res?.data?.success) {
        setNotify({ open: true, message: '送出成功！任務成功後請重新整理' });
        handleCancel();
      }
    })
    .catch((e) => setNotify({ open: true, message: '送出修改失敗' }));
  }

  const profile_page = `https://facebook.com/${account?.profile?.profile_id}`;
  const profile_image_path = useMemo(() => {
    if (uploadedFile) {
      return `/files/${uploadedFile.filename}?namespace=${uploadedFile.namespace}`
    }
    return `/files/${account?.profile?.profile_photo_path}?namespace=${PHOTO_NAMESPACE}`
  },
    [uploadedFile, account]
  );
  const collegeOptions = useMemo(() => {
    return [ ...new Set(departments.map(d => d.college))]
      .map(d => ({ name: d, value: d }))
  }, [departments])
  const departmentOptions = useMemo(() => {
    return departments
      .filter(d => d.college === selectedCollege)
      .map(d => d.department)
      .map(d => ({ name: d, value: d }))
  }, [departments, selectedCollege])


  return (
    <>
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Paper elevation={0} className={classes.basicInfo}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              src={profile_image_path}
              onChange={handleFileUpload}
            />
          </Grid>
          <Grid item style={{ marginLeft: 12 }}>
            <a href={profile_page} target="_blank" rel="noreferrer">
              <h2>{ account?.name }</h2>
            </a>
          </Grid>
          <Grid item style={{ marginLeft: 12 }}>
            <Chip
              label={account?.status}
              classes={{ root: classes[states[account?.status.toLowerCase()]] }}
            />
          </Grid>
          <Grid item style={{ marginLeft: 12 }}>
          {
            (account?.tags || []).map(tag => (
              <Chip className={classes.tagChip} label={tag} />
            ))
          }
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center" style={{ marginTop: 12 }}>
          <Grid item xs={12} lg={6}>
            <Select
              className={classes.formItem}
              variant="outlined"
              label="誰可以看到未來發文:"
              name="future_posts_privacy"
              value={profile?.future_posts_privacy || 'public'}
              options={PRIVACY_OPTIONS}
              onChange={handleChangeFuturePostsPrivacy}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Select
              className={classes.formItem}
              variant="outlined"
              label="誰可以看到過去大頭貼:"
              value={profile?.profile_pic_privacy || 'public'}
              name="profile_pic_privacy"
              options={PRIVACY_OPTIONS}
              onChange={handleChangeProfilePicPrivacy}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} className={classes.platformInfo}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    className={classes.formItem}
                    variant="outlined"
                    label="姓"
                    inputProps={field}
                    disabled
                    onChange={({ target }) => {
                      setValue('last_name', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'last_name'])
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    className={classes.formItem}
                    variant="outlined"
                    label="名"
                    inputProps={field}
                    disabled
                    onChange={({ target }) => {
                      setValue('first_name', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'first_name'])
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    className={classes.formItem}
                    variant="outlined"
                    label="性別"
                    name="gender"
                    options={[{ name: '男性', value: 'male' }, { name: '女性', value: 'female' }]}
                    value={value}
                    onChange={({ target }) => {
                      setValue('gender', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'gender'])
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="relationship"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    className={classes.formItem}
                    variant="outlined"
                    label="感情狀態"
                    name="relationship"
                    options={RELATIONSHIP_OPTIONS}
                    value={value}
                    onChange={({ target }) => {
                      setValue('relationship', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'relationship'])
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="birthday"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    className={classes.formItem}
                    variant="outlined"
                    type="date"
                    label="出生日期"
                    name="birthday"
                    inputProps={field}
                    onChange={({ target }) => {
                      setValue('birthday', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'birthday'])
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="college"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SearchSelect
                    className={classes.formItem}
                    variant="outlined"
                    label="學校"
                    name="college"
                    options={collegeOptions}
                    value={value}
                    onChange={({ target }) => {
                      setValue('college', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'college'])
                      setSelectedCollege(target.value)
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="department"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SearchSelect
                    className={classes.formItem}
                    variant="outlined"
                    label="科系"
                    name="department"
                    options={departmentOptions}
                    value={value}
                    onChange={({ target }) => {
                      setValue('department', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'department'])
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="hometown"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SearchSelect
                    className={classes.formItem}
                    variant="outlined"
                    label="家鄉"
                    name="hometown"
                    options={places.map(p => ({ name: p.place, value: p.place }))}
                    value={value}
                    onChange={({ target }) => {
                      setValue('hometown', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'hometown'])
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="current_city"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SearchSelect
                    className={classes.formItem}
                    variant="outlined"
                    label="居住地"
                    name="current_city"
                    options={places.map(p => ({ name: p.place, value: p.place }))}
                    value={value}
                    onChange={({ target }) => {
                      setValue('current_city', target.value, {
                        shouldValidate: true,
                      });
                      setModifiedFields([...modifiedFields, 'current_city'])
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              className={classes.formItem}
              variant="outlined"
              label="備註"
              name="description"
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Button
              className={classes.mr1}
              variant="contained"
              color={modifiedFields.length > 0 ? "primary" : ""}
              disabled={modifiedFields.length === 0}
              size="large"
              onClick={handleCancel}
            >
              取消
            </Button>
            <Button
              variant="contained"
              color={modifiedFields.length > 0 ? "primary" : ""}
              disabled={modifiedFields.length === 0}
              size="large"
              onClick={handleSubmit(onSubmit)}
            >
              修改
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { Grid, Button, IconButton, Chip, TextField } from '@material-ui/core';
import DataTable from '@components/core/DataTable';
import PageTitle from '@components/core/PageTitle';
import Info from '@components/core/Info';
import popup from '@components/core/Popup';
import useAPI from '@/services/api';
import useTargets from '@/hooks/useTargets';
import { useUserState } from '@/context/UserContext';

import Modal from './components/Modal';
import AccountsList from './components/AccountsList';

const TYPES = {
  'PAGE': '粉絲專頁',
  'GROUP': '社團',
}

export default function Targets() {
  const { user } = useUserState()
  const { targets, remove, create, update } = useTargets();
  const {
    data: accounts,
  } = useAPI(`/users/${user?.id}/accounts`);
  const [notify, setNotify] = useState({ open: false, message: '' });
  const [editingTagRows, setEditingTagRows] = useState([]);

  const handleTglAddTarget = () => {
    popup({
      title: '清單設定',
      content: (
        <Modal
          onSubmit={create}
        />
      )
    })
  }
  const handleAddTag = (idx) => {
    setEditingTagRows([ ...editingTagRows, idx ])
  }
  const handleUpdateTags = (idx) => (e) => {
    if (e.charCode === 13) {
      const tag = e.target.value;
      if (tag !== '') {
        update(targets[idx].id, { tags: [...targets[idx]['tags'], tag] })
      }
      setEditingTagRows(editingTagRows.filter(row => row !== idx))
    }
  }
  const handleDeleteTag = (idx, tag) => (_) => {
    update(targets[idx].id, { tags: targets[idx]['tags'].filter(t => t !== tag) })
  }

  const columns = [
    {
      name: 'index',
      label: '項次',
      options: {
        filter: false,
        download: false,
        customBodyRenderLite: (idx) => <>{idx + 1}</>,
      },
    },
    {
      name: 'id',
      label: '項次',
      options: { display: false, filter: false, download: false },
    },
    {
      name: 'name',
      label: '名稱',
      options: { filter: false },
    },
    {
      name: 'platform',
      label: '平台',
      options: {
        customBodyRender: (platform) => platform.type,
      }
    },
    {
      name: 'platform',
      label: '類型',
      options: {
        customBodyRender: (platform) => TYPES[platform?.page_type],
        filter: true,
        filterOptions: {
          names: Object.keys(TYPES),
          renderValue: type => TYPES[type],
          logic: (value, filters, row) => {
            return !filters.includes(value.page_type)
          }
        },
        customFilterListOptions: {
          render: (type) => '類型: ' + TYPES[type],
        },
      }
    },
    {
      name: 'url',
      label: '網址',
      options: {
        filter: false,
      }
    },
    {
      name: 'created_at',
      label: '創建時間',
      options: {
        filter: false,
        customBodyRender: (created_at) => dayjs.unix(created_at).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      name: "tags",
      options: {
        customBodyRenderLite: (idx) =>
          <>
            {
              targets[idx]?.tags.map(tag =>
                <Chip onDelete={handleDeleteTag(idx, tag)} label={tag} />
              )
            }
            {
              !editingTagRows.includes(idx) &&
              <IconButton
                aria-haspopup="true"
                color="inherit"
                aria-controls="profile-menu"
                onClick={() => handleAddTag(idx)}
              >
                <AddIcon />
              </IconButton>
            }
            {
              editingTagRows.includes(idx) &&
              <TextField
                //className={classes.tagInput}
                variant="outlined"
                size="small"
                onKeyPress={handleUpdateTags(idx)}
                autoFocus
              />
            }
          </>
      }
    },
    {
      name: 'id',
      label: '操作',
      options: {
        filter: false,
        customBodyRender: (id) => 
          <>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            aria-controls="profile-menu"
            onClick={() => remove(id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          </>
      }
    },
  ];

  return (
    <>
      <PageTitle
        title="目標清單"
        extra={
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleTglAddTarget}
          >
            新增目標清單
          </Button>
        }
      />
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            data={targets}
            columns={columns}
            options={{
              elevation: 0,
              expandableRows: true,
              renderExpandableRow: (values) => <AccountsList row={values} accounts={accounts} />,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export const ACTION_NAME = {
  'like.post': '主回文按讚',
  'unlike.post': '收回讚',
  'create.post': '發表文章',
  'comment.post': '文章留言',
  'reply.post': '留言回覆',
  'share.post': '分享',
  report: '檢舉',
  'batch.report': '批次檢舉',
  'join.page': '粉絲團按讚',
  'join.group': '加入社團',
  'update.profile': '更改個人資料',
  'edit.privacy': '更改權限',
  'add.friend': '加好友',
  'edit.post': '修改文章',
  'edit.comment': '修改留言',
  "yt.create.video": "發布影片",
  "yt.like.video": "影片按讚",
  "yt.comment.video": "影片留言",
  "yt.subscribe.channel": "(取消)訂閱頻道",
  "yt.reply.comment": "回覆留言",
  "yt.edit.name": "修改名稱",
};

export const TASK_STATUS = {
  INIT: '初始化',
  RUNNING: '執行中',
  DONE: '完成',
  FAIL: '失敗',
};

export const PROFILE_FIELDS = {
  'profile_photo': '大頭貼',
  'last_name': '姓',
  'first_name': '名',
  'gender': '性別',
  'relationship': '感情狀態',
  'birthday': '出生日期',
  'college': '學校',
  'department': '科系',
  'current_city': '居住地',
  'hometown': '家鄉',
}

export const formatUrl = (setting) => {
  const host = 'https://www.facebook.com'
  
  if (setting.post_url) {
    return setting.post_url
  }
  if (setting.target_type === 'self') {
    return `${host}/me`
  }
  if (setting.target_type === 'friend') {
    return `${host}/${setting.target_id}`
  }
  if (setting.target_type === 'group') {
    return `${host}/groups/${setting.target_id}`
  }
  if (setting.target_type === 'page') {
    return `${host}/pages/${setting.target_id}`
  }

  return setting.target_id
    ? `${host}/${setting.target_id}`
    : `${host}/${setting.profile_id}`
}

const { ACTION_NAME } = require("@constants");

const TRIGGER_CONDITIONS = [
  {
    name: "手動執行",
    value: "once",
  },
  {
    name: "時間排程",
    value: "schedule",
  },
  {
    name: "事件觸發",
    value: "event",
    disabled: true,
  },
];

const ACTION_TYPE = [
  {
    name: ACTION_NAME["like.post"],
    value: "like.post",
  },
  {
    name: ACTION_NAME["create.post"],
    value: "create.post",
  },
  {
    name: ACTION_NAME["comment.post"],
    value: "comment.post",
  },
  {
    name: ACTION_NAME["reply.post"],
    value: "reply.post",
  },
  {
    name: ACTION_NAME["share.post"],
    value: "share.post",
  },
  {
    name: ACTION_NAME["report"],
    value: "report",
  },
  {
    name: ACTION_NAME["join.page"],
    value: "join.page",
  },
  {
    name: ACTION_NAME["join.group"],
    value: "join.group",
  },
  {
    name: ACTION_NAME["add.friend"],
    value: "add.friend",
  },
];

const YT_ACTION_TYPE = [
  {
    name: ACTION_NAME["yt.create.video"],
    value: "yt.create.video",
  },
  {
    name: ACTION_NAME["yt.like.video"],
    value: "yt.like.video",
  },
  {
    name: ACTION_NAME["yt.comment.video"],
    value: "yt.comment.video",
  },
  {
    name: ACTION_NAME["yt.subscribe.channel"],
    value: "yt.subscribe.channel",
  },
  {
    name: ACTION_NAME["yt.reply.comment"],
    value: "yt.reply.comment",
  }
]

const TARGET_TYPE = [
  {
    name: "個人頁",
    value: "self",
  },
  {
    name: "朋友",
    value: "friend",
  },
  {
    name: "粉絲團",
    value: "page",
  },
  {
    name: "社團",
    value: "group",
  },
];

const REACT_TYPE = [
  {
    name: "讚",
    value: "Like",
  },
  {
    name: "大心",
    value: "Love",
  },
  {
    name: "加油",
    value: "Care",
  },
  {
    name: "哈",
    value: "Haha",
  },
  {
    name: "哇",
    value: "Wow",
  },
  {
    name: "嗚",
    value: "Sad",
  },
  {
    name: "怒",
    value: "Angry",
  },
];

const PLATFORMS = [
  {
    name: "Facebook",
    value: "facebook",
  },
  {
    name: "Youtube",
    value: "youtube",
  },
]

export { TRIGGER_CONDITIONS, ACTION_TYPE, TARGET_TYPE, REACT_TYPE, PLATFORMS, YT_ACTION_TYPE };

import { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyle = makeStyles({
  root: {
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    '&:hover p,&:hover svg,& img': {
      opacity: 1,
    },
    '& p, svg': {
      opacity: 0.4,
    },
    '&:hover img': {
      opacity: 0.3,
    },
  },
  noMouseEvent: {
    pointerEvents: 'none',
  },
  border: {
    border: '2px #aaa dotted',
  },
  iconText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
  },
  hidden: {
    display: 'none',
  },
  onDragOver: {
    '& img': {
      opacity: 0.3,
    },
    '& p, svg': {
      opacity: 1,
    },
  },
});

const FileUpload = ({
  accept,
  hoverLabel = 'Click or drag to upload file',
  dropLabel = 'Drop file here',
  width = '600px',
  height = '100px',
  backgroundColor = '#fff',
  image: {
    url = '',
    imageStyle = {
      height: 'inherit',
    },
  } = {},
  onChange = () => {},
  onDrop = () => {},
}) => {
  const classes = useStyle();
  const [fileUrl, setFileUrl] = useState(url);
  const [fileName, setFileName] = useState('');
  const [labelText, setLabelText] = useState(hoverLabel);
  const [isDragOver, setIsDragOver] = useState(false);
  //   const [isMouseOver, setIsMouseOver] = useState(false);
  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    // onMouseEnter: () => {
    //   setIsMouseOver(true);
    // },
    // onMouseLeave: () => {
    //   setIsMouseOver(false);
    // },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      if (e.dataTransfer.files[0]) {
        setFileName(e.dataTransfer.files[0].name);
        setFileUrl(URL.createObjectURL(e.dataTransfer.files[0]));
      }
      onDrop(e);
      onChange(e);
    },
  };

  const handleChange = (event) => {
    if (event.target.files[0]) {
      setFileName(event.target.files[0].name);
      setFileUrl(URL.createObjectURL(event.target.files[0]));
    }
    onChange(event);
  };

  return (
    <>
      <input
        onChange={handleChange}
        accept={accept}
        className={classes.hidden}
        id="file-upload"
        type="file"
      />

      <label htmlFor="file-upload" {...dragEvents}>
        <Box
          width={width}
          height={height}
          bgcolor={backgroundColor}
          className={classNames(classes.noMouseEvent, classes.border)}
        >
          <Box height={height} width="50%" className={classes.iconText}>
            <CloudUploadIcon fontSize="large" />
            <Typography>{labelText}</Typography>
            {fileName}
          </Box>
        </Box>
      </label>
    </>
  );
};

export default FileUpload;

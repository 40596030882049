import React from 'react';
import { Grid } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableRow, TableHead, TableCell } from '@material-ui/core';

import { PROFILE_FIELDS } from '@constants'

export default function TaskDetail({ task }) {
  if (task.type === 'update.profile') {
    return <ProfileTask task={task} />
  }
  if (task.type === 'edit.privacy') {
    return <PrivacyTask task={task} />
  }
  if (task.type === 'add.friend') {
    return <AddFriendTask task={task} />
  }

  return <Task task={task} />
}

function ProfileTask({ task }) {
  return (
    <TableContainer style={{ minWidth: 300 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>欄位</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          task.target_types.map(type => (
            <TableRow>
              <TableCell>
                { PROFILE_FIELDS[type] || type }
              </TableCell>
              <TableCell>
                { task?.doc[type] }
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Task({ task }) {
  const url = task?.result_url || task?.post_url || task?.url;

  return (
    <Grid container spacing={4}>
      <Grid item xs={2}>
        網址:
      </Grid>
      <Grid item xs={10}>
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      </Grid>
      <Grid item xs={2}>
        內容:
      </Grid>
      <Grid item xs={10}>
        {task?.content}
      </Grid>
    </Grid>
  );
}

function PrivacyTask({ task }) {
  const targetMap = {
    future_posts: '誰可以觀看未來發文',
    profile_pic: '誰可以看到過去大頭貼'
  }
  const privacyMap = {
    public: '公開',
    friends: '只限朋友',
    only_me: '只限自己',
  }

  return (
    <TableContainer style={{ minWidth: 300 }}>
      <Table>
        <TableBody>
         <TableRow>
           <TableCell>
             { targetMap[task?.target] || task?.target }
           </TableCell>
           <TableCell>
             { privacyMap[task?.privacy_type] || task?.privacy_type}
           </TableCell>
         </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AddFriendTask({ task }) {
  return (
    <Grid container spacing={4} style={{ width: 400 }}>
      <Grid item xs={4}>
        好友ID: 
      </Grid>
      <Grid item xs={7}>
        { task.profile_id }
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  Chip,
  IconButton,
  Select,
  OutlinedInput,
  Box,
  MenuItem,
  Button,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  People as PeopleIcon,
} from '@material-ui/icons';

export default function TagSetting({
  tags,
  defaultTags,
  handleUpdateTags,
  tagSetting,
  handleSortTags = () => {},
}) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const selected = defaultTags
      .sort((a, b) => tags.find(t => t.id === a.id)?.sn - tags.find(t => t.id === b.id)?.sn)
      .map(t => t.id);

    setSelectedTags(selected);
  }, [defaultTags, tags]);

  const SortableItem = SortableElement(({ value }) => {
    const tag = tags.find((t) => t.id === value);
    const icon = tag.type === 2 ? <PeopleIcon style={{color: '#FFFFFF'}} /> : <></>;
    return (
      <Chip
        key={value}
        icon={icon}
        label={tags.find((t) => t.id === value)?.name}
        style={{
          backgroundColor: tags.find((t) => t.id === value)?.color,
          color: '#fff',
          marginRight: 8,
          cursor: 'move',
        }}
      />
    )
  })
  const SortableList = SortableContainer(({items}) => {
    return (
      <ul style={{ padding: 0 }}>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </ul>
    );
  });

  const handleTagsSorted = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }
    handleSortTags(tags[oldIndex], tags[newIndex])
  }

  return (
    <>
      {editing ? (
        <Select
          multiple
          value={selectedTags}
          onChange={(e) => setSelectedTags(e.target.value)}
          input={<OutlinedInput label="Chip" />}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const currentTag = tags.find((t) => t.id === value);
                  return (
                    <Chip
                      key={value}
                      label={currentTag?.name}
                      style={{
                        backgroundColor: currentTag?.color,
                        color: '#fff',
                        marginRight: 8,
                      }}
                    />
                  );
                })}
              </Box>
            );
          }}
        >
          {tags.map((item) => (
            <MenuItem key={item.name} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <SortableList items={selectedTags} axis='x' onSortEnd={handleTagsSorted}/>
      )}
      {tagSetting && (
        <>
          {editing ? (
            <Button
              fontSize="small"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                handleUpdateTags(selectedTags);
                setEditing(!editing);
              }}
              style={{
                marginLeft: '0.5rem',
              }}
            >
              確認
            </Button>
          ) : (
            <IconButton color="inherit" onClick={() => setEditing(!editing)}>
              <EditIcon color="action" fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </>
  );
}

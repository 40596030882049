import React from 'react';
import MUIDataTable from 'mui-datatables';

export default function DataTable({ title, data, columns, options }) {
  return (
    <MUIDataTable
      title={title || '列表'}
      data={data}
      columns={columns}
      options={{
        download: false,
        elevation: false,
        filterType: 'checkbox',
        // filter: false,
        // search: false,
        selectableRows: 'none',
        viewColumns: false,
        print: false,
        pagination: true,
        ...options,
      }}
    />
  );
}

const notification = {
  error: console.log
}

function authHeaders() {
  return {
    'Authorization': localStorage.id_token,
    'Content-Type': 'application/json',
  }
}

function handler(res) {
  if (res.status >= 400) {
    return Promise.reject(res.json());
  }

  return res.json()
}

function authGet(url) {
  return fetch(url, {
    method: 'GET',
    headers: authHeaders(),
  })
  .catch(e => {
    notification.error({
      message: 'Request error',
      description: e.message
    })

    return Promise.reject(e);
  })
  .then(handler)
}

function authDelete(url) {
  return fetch(url, {
    method: 'DELETE',
    headers: authHeaders(),
  })
  .catch(e => {
    notification.error({
      message: 'Request error',
      description: e.message
    })

    return Promise.reject(e);
  })
  .then(handler)
} 

function authPost(url, data, method='POST') {
  return fetch(url, {
    method,
    body: JSON.stringify(data),
    headers: authHeaders(),
  })
  .catch(e => {
    notification.error({
      message: 'Request error',
      description: e.message
    })

    return Promise.reject(e);
  })
  .then(handler)
}

export { authGet, authDelete, authPost };

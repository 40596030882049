import { TableCell, TableRow } from '@material-ui/core';
import DataTable from '@components/core/DataTable';

export default function AccountsList({ row, accounts }) {
  const url = row[5]
  const isPageGroup = url.includes('group')
  const groupID = url.split('/').pop()

  const groupAccounts = isPageGroup
    ? accounts.filter(acc => acc.groups.some(g => g.group_id === groupID))
    : []

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'name',
      label: '帳號',
    },
  ];

  return (
    <TableRow>
      <TableCell colSpan={8}>
        <DataTable title="帳號列表" data={groupAccounts} columns={columns} />
      </TableCell>
    </TableRow>
  );
}

import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import FileUploader from '@components/core/FileUploader';
import useStepStore from '@hooks/useStepStore';
import { parseExcel } from '@/services/files';

const Step1 = ({ handleNext }) => {
  const [done, setDone] = useState(false);
  const { setExcelData } = useStepStore();

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    parseExcel(formData).then((data) => {
      setExcelData(data.payload);
    });
    if (file) {
      setDone(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          pt: 2,
        }}
      >
        <Box>
          <FileUploader onChange={(e) => handleUploadFile(e)} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{ mr: 1 }}
          disabled={!done}
        >
          下一步
        </Button>
      </Box>
    </>
  );
};

export default Step1;

import React from 'react';
import { TableCell, TableRow, IconButton } from '@material-ui/core';
import {
  CallMade as CallMadeIcon,
  Description as DescriptionIcon,
} from '@material-ui/icons';
import DataTable from '@components/core/DataTable';
import popup from '@components/core/Popup';
import TaskDetail from '@components/shared/TaskDetail';
import TaskActions from '@components/shared/TaskActions';
import useAPI from '@/services/api';
import { ACTION_NAME } from '@/constants';

export default function AccountTasks({ id }) {
  const { data, update, reload } = useAPI(`/accounts/${id}/tasks`);
  const tasks = data.sort((a, b) => b.created_at - a.created_at);

  const handleShowDetail = (idx) => {
    popup({
      title: '詳細資料',
      content: (
        <TaskDetail task={tasks[idx]} />
      ),
      onCancel: () => {},
      onOk: () => {},
    });
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'flow_name',
      label: '所屬流程',
      options: {
        customBodyRender: (name) => name && name.slice(0, 30),
      },
    },
    {
      name: 'type',
      label: '動作',
      options: {
        customBodyRender: (type) => ACTION_NAME[type] || type,
      },
    },
    {
      name: 'created_at',
      label: '開始時間',
      options: {
        customBodyRenderLite: (idx) =>
          new Date(tasks[idx].created_at * 1000).toLocaleString(),
      },
    },
    {
      name: 'updated_at',
      label: '上次更新',
      options: {
        customBodyRenderLite: (idx) =>
          tasks[idx].updated_at &&
          new Date(tasks[idx].updated_at * 1000).toLocaleString(),
      },
    },
    {
      name: 'status',
      label: '狀態',
    },

    {
      name: 'action',
      label: '操作',
      options: {
        customBodyRenderLite: (idx) => (
          <>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              onClick={() => handleShowDetail(idx)}
            >
              <DescriptionIcon />
            </IconButton>
            {
              tasks[idx]?.status === 'DONE' &&
              <IconButton
                aria-haspopup="true"
                color="inherit"
                aria-controls="profile-menu"
                href={tasks[idx]?.result_url || tasks[idx]?.post_url}
                target="_blank"
              >
                <CallMadeIcon />
              </IconButton>
            }
            <TaskActions task={tasks[idx]} update={update} reload={reload} />
          </>
        ),
      },
    },
  ];

  return (
    <TableRow>
      <TableCell colSpan={8}>
        <DataTable
          title="任務列表"
          data={tasks}
          columns={columns}
          options={{
            filterArrayFullMatch: false,
          }}
        />
      </TableCell>
    </TableRow>
  );
}

import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  TripOrigin as TripOriginIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import { Badge, Typography } from '../../Wrappers';
import Notification from './components/Notification';

// context
import {
  useLayoutDispatch,
  toggleSidebar,
} from '../../../context/LayoutContext';
import {
  useUserState,
  useUserDispatch,
  signOut,
} from '../../../context/UserContext';
import useNotifications from '@hooks/useNotifications';

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var { user } = useUserState();

  // local
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(false);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);

  const { notifications, markAsRead } = useNotifications();
  const unReadCount = notifications.filter(n => !n.is_read).length;

  const handleMarkAsRead = (notiID) => {
    markAsRead(notiID)
  }

  useEffect(
    () => {
      if (notifications.some(row => !row.is_read)) {
        setIsNotificationsUnread(true)
      }
    },
    [notifications, setIsNotificationsUnread]
  )

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          <MenuIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
        <Typography variant="h2" weight="medium" className={classes.logotype}>
          {process.env.REACT_APP_TITLE || 'MetaPR'}
        </Typography>
        <div className={classes.grow} />
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setNotificationsMenu(e.currentTarget);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? unReadCount : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => {
                setNotificationsMenu(null)
                handleMarkAsRead(notification.id)
              }}
              className={classes.headerMenuItem}
            >
              <Notification
                {...notification}
                typographyVariant="inherit"
                extraButton={
                  notification.is_read
                    ? null
                    : <div style={{ marginLeft: 32, paddingTop: 6 }}>
                        <TripOriginIcon color="primary" />
                      </div>
                }
              />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {user && user.email && user.email.split('@').shift()}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Messages
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

import React, { useState } from 'react';
import { Grid, IconButton, Chip } from '@material-ui/core';
import {
  PlayArrow as PlayArrowIcon,
  Description as DescriptionIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';
import DayJsUtils from '@date-io/dayjs';
import DataTable from '@components/core/DataTable';
import useAPI from '@/services/api';
import { run } from '@/services/flows';
import useTags from '@hooks/useTags';
import { useUserState } from '@/context/UserContext';
import { ACTION_NAME } from '@/constants';
import PageTitle from '@components/core/PageTitle';
import Info from '@components/core/Info';
import popup from '@components/shared/Popup';
import FlowDetail from '@components/shared/FlowDetail';
import FlowTasks from './components/FlowTasks';
import useStyles from './styles';

export default function Flows() {
  const classes = useStyles();
  const { user } = useUserState();
  const { tags } = useTags('task');
  const [startDate, setStartDate] = useState(dayjs().subtract(10, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [notify, setNotify] = useState({ open: false, message: '' });
  const [expandedRows, setExpandedRows] = useState([]);

  const { data: flows } = useAPI(
    `/users/${user.id}/flows?start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`
  );

  function handleRunFlow(idx, id) {
    run(id)
      .then(() => {
        setNotify({ open: true, message: '成功' });
        setExpandedRows([0]);
        setExpandedRows([idx]);
      })
      .catch((e) => setNotify({ open: true, message: '失敗' }));
  }

  function handleExpansionChange(_, rows) {
    setExpandedRows(rows.map((r) => r.index));
  }

  function handleShowDetail(idx) {
    popup({
      title: flows[idx]?.name.slice(0, 30),
      content: (
        <FlowDetail
          flow={flows[idx]}
          url={flows[idx]?.action.post_url}
          content={flows[idx]?.action.content}
        />
      ),
      onCancel: () => {},
      onOk: () => {},
    });
  }

  const columns = [
    {
      name: 'index',
      label: '項次',
      options: {
        filter: false,
        customBodyRenderLite: (idx) => <>{idx + 1}</>,
      },
    },
    {
      name: 'id',
      options: { display: false, filter: false },
    },
    {
      name: '名稱',
      options: {
        filter: false,
        customBodyRenderLite: (idx) =>
          flows[idx].name && flows[idx].name.slice(0, 30),
      },
    },
    {
      name: '平台',
      options: {
        filter: false,
        customBodyRenderLite: (idx) =>
          flows[idx].platform || 'facebook'
      },
    },
    {
      name: '創建於',
      options: {
        filter: false,
        customBodyRenderLite: (idx) =>
          dayjs.unix(flows[idx].created_at).format('YYYY-MM-DD HH:mm'),
      },
    },
    {
      name: 'action',
      label: '動作',
      options: {
        filter: true,
        filterOptions: {
          names: Object.keys(ACTION_NAME),
          renderValue: key => ACTION_NAME[key],
          logic: (location, filters, row) => !filters.includes(location.type),
        },
        customFilterListOptions: {
          render: key => ACTION_NAME[key],
        },
        customBodyRenderLite: (idx) => {
          const actionType = flows[idx]?.action?.type || flows[idx]?.actions[0]?.type;

          return ACTION_NAME[actionType] || actionType
        }
      },
    },
    {
      name: 'tags',
      label: '標籤',
      options: {
        filter: true,
        filterOptions: {
          names: tags.map(t => t.id),
          renderValue: id => tags.find(t => t.id === id)?.name,
        },
        customFilterListOptions: {
          render: (id) => tags.find(t => t.id === id)?.name,
        },
        customBodyRenderLite: (idx) => {
          const tagIDs = flows[idx]?.tags;

          return tagIDs.map((id) => (
            <Chip
              key={id}
              label={tags.find((t) => t.id === id)?.name}
              style={{
                backgroundColor: tags.find((t) => t.id === id)?.color,
                color: '#fff',
              }}
            />
          ));
        },
      },
    },
    {
      name: '參與帳號數',
      options: {
        filter: false,
        customBodyRenderLite: (idx) =>
          (flows[idx].accounts && flows[idx].accounts.length) || 0,
      },
    },
    {
      name: '成功任務數',
      options: {
        filter: false,
        customBodyRenderLite: (idx) =>
          (flows[idx].statuses?.filter((s) => s === 'DONE').length) || 0,
      },
    },
    {
      name: '操作',
      options: {
        filter: false,
        customBodyRenderLite: (idx) => {
          return (
            <>
              <IconButton
                aria-haspopup="true"
                color="inherit"
                aria-controls="profile-menu"
                onClick={() => handleShowDetail(idx)}
              >
                <DescriptionIcon classes={{ root: classes.headerIcon }} />
              </IconButton>
              <IconButton
                aria-haspopup="true"
                color="inherit"
                aria-controls="profile-menu"
                onClick={() => {}}
              >
                <DeleteIcon classes={{ root: classes.headerIcon }} />
              </IconButton>
              {
                flows[idx]?.status !== 'done' && flows[idx]?.trigger?.type !== 'schedule' &&
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  aria-controls="profile-menu"
                  onClick={() => handleRunFlow(idx, flows[idx].id)}
                >
                  <PlayArrowIcon
                    classes={{
                      root: classes.headerIcon
                    }}
                    style={{ color: 'green' }}
                  />
                </IconButton>
              }
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <PageTitle
        title="流程"
        extra={
          <div style={{ float: 'right' }}>
            <MuiPickersUtilsProvider locale="en" utils={DayJsUtils}>
              <KeyboardDatePicker
                label="開始日期"
                format="YYYY-MM-DD"
                value={startDate}
                onChange={setStartDate}
              />
              <KeyboardDatePicker
                label="結束日期"
                format="YYYY-MM-DD"
                value={endDate}
                onChange={setEndDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        }
      />
      <Info
        open={notify.open}
        message={notify.message}
        onClose={() => setNotify({ open: false, message: '' })}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            data={flows}
            columns={columns}
            options={{
              elevation: 0,
              expandableRows: true,
              filterArrayFullMatch: false,
              renderExpandableRow: (cols) => <FlowTasks id={cols[1]} />,
              rowsExpanded: expandedRows,
              onRowExpansionChange: handleExpansionChange,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

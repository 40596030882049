import React from 'react';
import { IconButton } from '@material-ui/core';
import {
  Undo as UndoIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import popup from '@components/core/Popup';
import PostContentModal from '@components/shared/PostContentModal';
import useStyles from './styles';

export default function TaskActions({ task, update, reload }) {
  const classes = useStyles();

  const handleRunUnlikeTask = (task) => {
    update(task.id, { type: 'unlike.post', unlike: true }, 'POST')
      .then(reload)
  }
  const handleRunEditPostTask = (task) => (data) => {
    update(
      task.id,
      {
        type: 'edit.post',
        post_url: task.result_url || task.post_url || task.url,
        content: data.content,
      },
      'POST'
    )
    .then(reload)
  }
  const handleRunEditCommentTask = (task) => (data) => {
    update(
      task.id,
      {
        type: 'edit.comment',
        post_url: task.post_url,
        comment_url: task.result_url,
        content: data.content,
      },
      'POST'
    )
    .then(reload)
  }
  const handleRunDeletePost = (task) => {
    update(task.id, { type: 'edit.post', is_delete: '1' }, 'POST')
      .then(reload)
  }
  const handleRunDeleteComment = (task) => {
    update(task.id, { type: 'edit.comment', is_delete: '1' }, 'POST')
      .then(reload)
  }
  const handleEditPost = (task) => {
    popup({
      title: '發文編輯',
      content: (
        <PostContentModal
          data={task}
          handleOnSubmit={handleRunEditPostTask(task)}
        />
      ),
    });
  }
  const handleEditComment = (task) => {
    popup({
      title: '留言編輯',
      content: (
        <PostContentModal
          data={task}
          handleOnSubmit={handleRunEditCommentTask(task)}
        />
      ),
    });
  }

  return (
    <>
      {
        task?.type === 'like.post' && !task?.unlike &&
        <IconButton
          aria-haspopup="true"
          color="inherit"
          aria-controls="profile-menu"
          onClick={() => handleRunUnlikeTask(task)}
        >
          <UndoIcon
            classes={{
              root: classes.headerIcon,
            }}
          />
        </IconButton>
      }
      {
        task?.type === 'create.post' && task?.status === 'DONE' &&
        <>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            aria-controls="profile-menu"
            onClick={() => handleEditPost(task)}
          >
            <EditIcon
              classes={{
                root: classes.headerIcon,
              }}
            />
          </IconButton>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            aria-controls="profile-menu"
            onClick={() => handleRunDeletePost(task)}
          >
            <DeleteIcon
              classes={{
                root: classes.headerIcon,
              }}
            />
          </IconButton>
        </>
      }
      {
        task?.type === 'comment.post' && task?.status === 'DONE' && task?.result_url &&
        <>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            aria-controls="profile-menu"
            onClick={() => handleEditComment(task)}
          >
            <EditIcon
              classes={{
                root: classes.headerIcon,
              }}
            />
          </IconButton>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            aria-controls="profile-menu"
            onClick={() => handleRunDeleteComment(task)}
          >
            <DeleteIcon
              classes={{
                root: classes.headerIcon,
              }}
            />
          </IconButton>
        </>
      }
    </>
  );
}

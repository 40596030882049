import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import DataTable from '@components/core/DataTable';

import { formatUrl } from '@constants';

export default function AccountTasks({ id, actions }) {
  const data = actions
    .filter(a => a.account_id === id)
    .map(a => a.action)

  const columns = [
    {
      name: 'post_url',
      label: '連結',
      options: {
        customBodyRenderLite: (idx) => formatUrl(data[idx])
      }
    },
  ];

  return (
    <TableRow>
      <TableCell colSpan={8}>
        <DataTable
          title="參與目標連結"
          data={data}
          columns={columns}
          options={{
            filterArrayFullMatch: false,
          }}
        />
      </TableCell>
    </TableRow>
  );
}

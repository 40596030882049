import useSWR from 'swr';
import { Edit as EditIcon } from '@material-ui/icons';
import { Paper, TableContainer, Button, Box } from '@material-ui/core';
import fetcher from '@/libs/fetcher';

import { useUserState } from '@/context/UserContext';
import popup from '@components/core/Popup';
import DataTable from '@components/core/DataTable';
import PageTitle from '@components/core/PageTitle';
import useTargets from '@hooks/useTargets';

import Modal from './components/Modal';

const TASK_TYPE = {
  0: '任務',
  1: '帳號',
  2: '好友標籤',
};

function Tags({ Component, pageProps }) {
  const { user } = useUserState();
  const { targets } = useTargets();

  const {
    data: rowData,
    mutate,
  } = useSWR(user?.group_id ? `/users/${user?.id}/tags` : null, (url) =>
    fetcher({ url }),
  );

  const handleEdit = (idx) => {
    popup({
      title: '標籤設定',
      content: (
        <Modal
          updateList={() => mutate()}
          data={rowData.data[idx]}
          user={user}
          allTargets={targets}
        />
      ),
    });
  };

  const handleSelect = (a, b, selectableRows) => {};
  const handleDelete = (ids) => {
    console.log('🚀 ~ file: index.js ~ line 47 ~ handleDelete ~ ids', ids);
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'id',
      label: '項次',
      options: {
        filter: false,
        customBodyRenderLite: (idx) => <>{idx + 1}</>,
      },
    },
    {
      name: 'name',
      label: '名稱',
    },
    {
      name: 'color',
      label: '標籤顏色',
      options: {
        customBodyRenderLite: (idx) => (
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: '20%',
              backgroundColor: rowData.data[idx]?.color,
            }}
          />
        ),
      },
    },
    {
      name: 'type',
      label: '標籤類型',
      options: {
        filter: false,
        customBodyRenderLite: (idx) => (
          <> {TASK_TYPE[rowData.data[idx]?.type]}</>
        ),
      },
    },
    {
      name: 'created_by',
      label: '創建使用者',
    },
    {
      name: 'action',
      label: '操作',
      options: {
        filter: false,
        customBodyRenderLite: (idx) => {
          if ([1, 2].includes(rowData.data[idx]?.type)) {
            return (
              <EditIcon
                className="w-[20px] text-sm text-purple-700 cursor-pointer"
                onClick={() => handleEdit(idx)}
              />
            );
          }
        },
      },
    },
  ];

  return (
    <>
      <PageTitle
        title="標籤管理"
        extra={
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleEdit}
          >
            新增標籤
          </Button>
        }
      />
      <TableContainer component={Paper} className="mt-3 p-2">
        <DataTable
          data={rowData?.data}
          columns={columns}
          options={{
            // selectableRows: true,
            filterArrayFullMatch: false,
            onRowSelectionChange: handleSelect,
            onRowDelete: (rowsDeleted) => {
              handleDelete(rowsDeleted);
            },
          }}
        />
      </TableContainer>
    </>
  );
}

export default Tags;
